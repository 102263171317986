// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles';

import React, { FC } from 'react';
import {
  Container,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import { PropertyCheckArticleType, PropertyCheckPaymentMethod, AddPropertyCheckRequestData } from '../../types/propertyCheck';
import { getEnumValues } from '../../../../services/utils/enum';
import { DataType } from './types/formBuilder';
import FormBuilder from './components/FormBuilder';
import { KeyValueObject } from '../../../../types/common';
import { RootState } from '../../../../store/reducers';
import { submitPropertyCheckRequest } from '../../reducers/propertyCheck';
import useOnStateChange from '../../../../hooks/useOnStateChange';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footer: {
    borderWidth: '20px',
    borderColor: 'black',
    paddingTop: theme.spacing(2),
  },
}));

const schema = Yup.object().shape({
  userId: Yup.string().optional(),
  propertyId: Yup.string().optional(),
  propertyDesignation: Yup.string().optional(),
  name: Yup.string().required('Please specify a name'),
  phoneNumber: Yup.string().required('Please specify a phone number'),
  streetAddress: Yup.string().required('Please specify a street address'),
  postalCode: Yup.string().required('Please specify a postal code'),
  addressLocality: Yup.string().required('Please specify a locality'),
  addressCountry: Yup.string().required('Please specify a country'),
  email: Yup.string().required('Please specify an email').email('Please specify a valid email'),
  requestedDate: Yup.string().required('Please specify a date'),
  articleId: Yup.string().required('Please specify a articleId').oneOf(getEnumValues(PropertyCheckArticleType), 'Please specify correct value'),
  paymentMethodId: Yup
    .string()
    .required('Please specify a paymentMethodId')
    .oneOf(getEnumValues(PropertyCheckPaymentMethod), 'Please specify correct value'),
  price: Yup.number()
    .required('Please specify a price')
    .transform((o, v) => Number(v?.replace(/,/, '.')))
    .typeError('Please fix the value'),
  campaignPrice: Yup.number()
    .transform((o, v) => Number(v?.replace(/,/, '.')))
    .typeError('Please fix the value'),
  campaignName: Yup
    .string()
    .optional(),
});

const DEFAULT_FORM_VALUES: AddPropertyCheckRequestData = {
  userId: undefined,
  propertyId: undefined,
  propertyDesignation: undefined,
  name: undefined,
  phoneNumber: undefined,
  email: undefined,
  streetAddress: '',
  postalCode: '',
  addressLocality: '',
  addressCountry: 'SE',
  requestedDate: new Date(),
  price: undefined,
  campaignPrice: undefined,
  articleId: PropertyCheckArticleType.WITH_BASIC_INFO,
  paymentMethodId: PropertyCheckPaymentMethod.INVOICE,
  campaignName: undefined,
};

const AddPropertyCheckRequest: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const propertyCheckSubmitting = useSelector(({ propertyCheck }: RootState) => propertyCheck.propertyCheckLoading);
  const propertyCheckError = useSelector(({ propertyCheck }: RootState) => propertyCheck.propertyCheckError);

  const goBackIfSuccess = () => {
    if (propertyCheckError) {
      return;
    }
    navigate(-1);
  };

  const proceedAfterSubmit = useOnStateChange(({ propertyCheck }: RootState) => propertyCheck.propertyCheckLoading, goBackIfSuccess);

  const {
    values, errors, touched, setFieldValue, handleBlur, isValid, dirty, submitForm,
  } = useFormik<AddPropertyCheckRequestData>({
    initialValues: DEFAULT_FORM_VALUES,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (data: AddPropertyCheckRequestData) => {
      dispatch(submitPropertyCheckRequest(data));
      proceedAfterSubmit();
    },
  });

  const createTextField = (
    id: string,
    label: string,
    required: boolean = false,
    type: DataType = DataType.STRING,
    options: Array<KeyValueObject> = [],
  ) => ({
    id,
    label,
    required,
    value: values[id],
    onChange: (value) => setFieldValue(id, value),
    onBlur: handleBlur(id),
    error: errors[id],
    touched: touched[id],
    type,
    options,
  });

  const textFields = [
    createTextField('userId', 'UserID', false),
    createTextField('propertyId', 'PropertyID', false),
    createTextField('propertyDesignation', 'Property designation', false),
    createTextField('name', 'Name', true),
    createTextField('email', 'Email', true),
    createTextField('phoneNumber', 'Phone number', true),
    createTextField('requestedDate', 'Date', true, DataType.DATE),

    createTextField('addressCountry', 'Country', true),
    createTextField('postalCode', 'Postal code', true),
    createTextField('addressLocality', 'Address locality', true),

    createTextField('streetAddress', 'Street address', true),
    createTextField('articleId', 'Article type', true, DataType.LIST, getEnumValues(PropertyCheckArticleType)
      .map((item) => ({ key: item, value: item }))),
    createTextField('paymentMethodId', 'Payment type', true, DataType.LIST, getEnumValues(PropertyCheckPaymentMethod)
      .map((item) => ({ key: item, value: item }))),
    createTextField('price', 'Price', true),
    createTextField('campaignName', 'Campaign name', false),
    createTextField('campaignPrice', 'Campaign price', false),
  ];

  return (
    <Container component="main" maxWidth="lg" className={classes.paper}>
      <FormBuilder
        title="Enspecta - Property check"
        fields={textFields}
        disabled={!dirty || !isValid}
        onSubmit={submitForm}
        loading={propertyCheckSubmitting}
        FooterComponent={
          <div className={classes.footer}>
            <Link
              href="https://datastudio.google.com/u/0/reporting/65630262-154c-4d3d-86f6-e90d3bcd4e0d/page/p_xqlwvddlnc">
              Open report with all requests
            </Link>
          </div>
        }
      />

    </Container>
  );
};

export default AddPropertyCheckRequest;
