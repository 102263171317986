import { TextField } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { FC } from 'react';
import { FieldInfo } from '../types/formBuilder';

const FormDateTimePicker: FC<FieldInfo> = ({
  id, label, required, value, onChange, onBlur, error, touched,
}) => {
  const showError = Boolean(touched && error);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) =>
          <TextField
            {...params}
            id={id}
            required={required}
            onBlur={onBlur}
            error={showError}
            helperText={showError ? error : ''}
            fullWidth
            variant="standard"
          />
        }
      />
    </LocalizationProvider>
  );
};
export default FormDateTimePicker;
