import { ArticleType } from '../modules/Articles/types/articles';
import { DynamicLink } from '../types/entities';

export const addToArticlesArray = (articlesArray: any, article: any) => {
  if (article.type === ArticleType.LINK) {
    articlesArray.unshift(article);
  }
  return articlesArray;
};

export const updateArticlesArray = (articlesArray: any, article: any) => articlesArray.map((art: any) => (art.id === article.id ? article : art));

export const addToNews = (articleNews: any, news: any) => {
  if (news.type === ArticleType.NEWS) {
    articleNews.push(news);
  }
  return articleNews;
};

export const addToCreatedNews = (createdNews: any, dataObj: any) => {
  const { expertId, data } = dataObj;
  if (data.type === ArticleType.NEWS) {
    Object.assign(createdNews, { expertId, ...data });
  }
  return createdNews;
};

export const addToCreatedArticle = (createdArticle: any, dataObj: any) => {
  const { expertId, data } = dataObj;
  if (data.type === ArticleType.LINK) {
    Object.assign(createdArticle, { expertId, ...data });
  }
  return createdArticle;
};

export const addToDynamicLinks = (dynamicLinks: Array<any>, id: string, link: DynamicLink) => {
  dynamicLinks.push({ id, link });
  return dynamicLinks;
};
