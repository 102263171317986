// @ts-nocheck
import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CreateArticleForm from './components/createArticleFormik';

export default function CreateArticle() {
  const { id, expertId } = useParams();
  const { pathname } = useLocation();
  return <CreateArticleForm expertId={expertId} id={id} pathname={pathname} />;
}
