import {
  request, getAPIPath, getAdminAPIPath, unauthorizedRequest,
} from '../../../api/core';
import { AppSettings } from '../../../settings/appSettings';

const BUNDLE_ID = 'se.houseid.app';

export const fetchArticles = (pageNumber: number, pageSize: number) =>
  request(getAdminAPIPath(`articles?pageSize=${pageSize}&offset=${pageNumber * pageSize}`), {
    method: 'GET',
  });

export const createArticle = (data: any) => request(getAdminAPIPath('articles'), {
  method: 'POST',
  data,
});

export const fetchArticleCategories = () => request(getAPIPath('articles/categories'), {
  method: 'GET',
});

export const updateArticle = (data: any, id: string) => request(getAdminAPIPath(`articles/${id}`), {
  method: 'PATCH',
  data,
});

export const deleteArticle = (id: string) => request(getAdminAPIPath(`articles/${id}`), {
  method: 'DELETE',
});

export const getDynamicLink = (link: string, id: string) => {
  const config = AppSettings.firebaseConfig;
  const linkHasQueryParam = link.indexOf('?') !== -1;
  return unauthorizedRequest(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${config.apiKey}`, {
    method: 'POST',
    data: {
      dynamicLinkInfo: {
        domainUriPrefix: `${config.domainUriPrefix}`,
        link: linkHasQueryParam
          ? `${link}&action=houseid://app/detailsArticle/${id}`
          : `${link}?action=houseid://app/detailsArticle/${id}`,
        androidInfo: {
          androidPackageName: BUNDLE_ID,
        },
        iosInfo: {
          iosBundleId: BUNDLE_ID,
        },
      },
    },
  });
};

export const fetchNews = (pageNumber: number) => request(getAdminAPIPath(`articles?pageSize=100&offset=${pageNumber * 100}&type=news`), {
  method: 'GET',
});

export const searchArticles = (searchQuery: string) => request(getAdminAPIPath(`articles?titleWildcard=${searchQuery}`), {
  method: 'GET',
});

export const fetchArticle = (id: string) => request(getAdminAPIPath(`articles/${id}`), {
  method: 'GET',
});
