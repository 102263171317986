import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import { AccountCircle } from '@material-ui/icons';
import {
  alpha, Menu, MenuItem, useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GridSearchIcon } from '@mui/x-data-grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import { logoutUser } from '../../modules/User/reducers/auth';
import { AppDrawerHeight } from '../../constants/common';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const RightContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  paddingLeft: theme.spacing(3),
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${AppDrawerHeight}px)`,
    marginLeft: `${AppDrawerHeight}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomAppBar = ({ open, onOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const currentUser = useSelector((state: any) => (state.auth.currentUser));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleDrawerOpen = () => {
    if (currentUser) {
      onOpen(true);
    }
  };

  const handleDrawerClose = () => onOpen(false);

  const handleLogoutClick = () => {
    handleDrawerClose();
    handleCloseMenu();
    dispatch(logoutUser());
  };

  return (
    <AppBar position="fixed" open={open} color="primary">

      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div">
          House:ID | Backoffice | {process.env.REACT_APP_FIREBASE_PROJECT} |
        </Typography>
        <RightContainer>
          <Search>
            <SearchIconWrapper>
              <GridSearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              readOnly
              placeholder="Search… "
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </RightContainer>
        {currentUser && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenMenu}
              color="inherit"
            >
              <Typography variant="subtitle2" component="div" style={{ marginRight: theme.spacing(1) }}>
                {currentUser.displayName}
              </Typography>
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleLogoutClick}>Log out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
