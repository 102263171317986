import _ from 'lodash';
import { useCallback } from 'react';

export const debounced = (f: Function, delay: number = 250) =>
  _.debounce(f as (...args: any) => any, delay);

const useDebouncedCallback = (f: Function, delay?: number) =>
  useCallback(debounced(f, delay), []);

export default useDebouncedCallback;
