import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Box, CircularProgress } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: 'red',
  },
}));

interface FormValues {
  email: string;
  password: string;
}

interface OtherProps {
  loginError: string;
}

function InnerForm(props: OtherProps & FormikProps<FormValues>) {
  const classes = useStyles();
  const theme = useTheme();
  const loading = useSelector((state: any) => (state.auth.loading));

  const {
    touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, values, loginError,
  } = props;

  return loading
    ? (
      <Box sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', padding: theme.spacing(4),
      }}>
        <CircularProgress size={80} />
      </Box>
    )
    : (
      <form
        className={classes.form}
        onSubmit={(e: any) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div>
          <TextField
            autoComplete="current-email"
            autoFocus
            error={(errors?.email && touched?.email) || false}
            fullWidth
            helperText={(errors.email && touched.email) ? errors.email : null}
            label="E-mail address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            variant="standard"
          />
        </div>

        <div>
          <TextField
            autoComplete="current-password"
            error={(errors?.password && touched?.password) || false}
            fullWidth
            helperText={((errors.password && touched.password) ? errors.password : null)}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="standard"
          />
        </div>

        {loginError ? (
          <div>
            <TextField
              defaultValue={loginError}
              disabled
              error
              fullWidth
              id="filled-disabled"
              variant="filled"
            />
          </div>
        ) : null}

        <div>
          <Button
            className={classes.submit}
            color="primary"
            disabled={isSubmitting}
            fullWidth
            type="submit"
            variant="contained"
          >
            Sign in
          </Button>
        </div>
      </form>
    );
}

export default InnerForm;
