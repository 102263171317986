import {
  Button, Avatar,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteDialog } from '../../../../../components';
import { deleteArticleRequest, fetchArticlesRequest } from '../../../reducers/articles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const useGetArticleColumns = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const renderAvatar = useCallback((params) => <Avatar variant="rounded" className={classes.large} src={params?.row.imageUrl} />, []);

  const handleArticleClick = (params) => {
    navigate(`/editArticle/${params.row.id}`);
  };

  return [
    {
      field: 'imageUrl',
      headerName: 'Avatar',
      flex: 0.5,
      renderCell: (params) => renderAvatar(params),
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 1.5,
      minWidth: 300,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 2,
    },
    {
      field: 'classifications',
      headerName: 'Classifications',
      flex: 1,
    },
    {
      headerName: 'Source',
      field: 'source',
      flex: 0.5,
      valueGetter: (params) => params?.row.source?.name,
    },
    {
      headerName: 'Last update',
      field: 'updatedAt',
      type: 'date',
      flex: 1,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      flex: 0.5,
      renderCell: (params) =>
        <Button color="primary" onClick={() => handleArticleClick(params)} variant="contained">EDIT</Button>,

    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      flex: 0.7,
      renderCell: (params) =>
        <DeleteDialog
          deleteMessage="Are you sure you want to delete article?"
          deleteRequest={deleteArticleRequest}
          deleteTitle="Delete article"
          fetchRequest={fetchArticlesRequest}
          id={params.id}
        />,
    },
  ];
};

export default useGetArticleColumns;
