import * as React from 'react';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

const DataGridToolbar: React.FC<{ children: React.ReactElement }> = ({ children }) => (
  <GridToolbarContainer>
    {children}
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport csvOptions={{
      delimiter: ';',
      utf8WithBom: true,
    }} />
  </GridToolbarContainer>
);

export default DataGridToolbar;
