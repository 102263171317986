import React from 'react';
import { Container, Button, makeStyles } from '@material-ui/core';

interface CenteredButtonProperties {
  disabled: boolean,
  title: string,
}

function SubmitButton({ disabled, title }: CenteredButtonProperties) {
  const useStyles = makeStyles((theme) => ({
    submit: {
      margin: theme.spacing(2, 0, 2),
    },
    centeredRow: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

  const classes = useStyles();
  return (
    <Container className={classes.centeredRow} maxWidth="xl">
      <Container maxWidth="xs">
        <Button
          className={classes.submit}
          color="primary"
          disabled={disabled}
          fullWidth
          type="submit"
          variant="contained"
        >
          {title}
        </Button>
      </Container>
    </Container>
  );
}
export default SubmitButton;
