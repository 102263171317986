import { withFormik } from 'formik';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import CreateProductForm from './CreateProductOfferForm';
import {
  ProductOffer, ProductOfferFormProps, PRODUCT_OFFER_TYPE, ANNONS_TYPE,
} from '../../../../../types/productOfferProperties';
import { creteProductOfferRequest, updateProductOfferRequest } from '../../../reducers/productOffer';
import { fetchExpertsRequest } from '../../../reducers/experts';

const schema = Yup.object().shape({
  title: Yup.string().required('Field shouldn\'t be empty'),
  imageUrl: Yup.string().required('Field shouldn\'t be empty'),
  price: Yup.number(),
  ordinaryPrice: Yup.number(),
  discount: Yup.number(),
  affiliate: Yup.string(),
  source: Yup.string(),
  url: Yup.string(),
});

const CreateProductOfferFormik = compose(
  connect(
    ({ productOffers, experts }: any) => ({ productOffers: productOffers?.productOffers, experts: experts.experts }),
    { creteProductOfferRequest, fetchExpertsRequest, updateProductOfferRequest },
  ),
  withFormik<ProductOffer & ProductOfferFormProps, any>({
    mapPropsToValues: (props: any) => {
      const {
        expertId, id, productOffers, experts,
      } = props;
      const offer = productOffers?.find((productOffer: any) => productOffer.id === id);
      return {
        title: id ? offer.name : '',
        imageUrl: id ? offer?.data?.imageUrl : '',
        price: id ? offer?.data?.price?.price : 0,
        ordinaryPrice: id ? offer?.data?.price?.ordinaryPrice : 0,
        discount: id ? offer?.data.price?.discount : 0,
        affiliate: id ? offer?.data.link?.affiliate : '',
        source: id ? offer?.data.link?.source : '',
        url: id ? offer?.data.link?.url : '',
        expertId: id ? offer?.expertId : expertId,
        validFrom: id ? offer?.validFrom : '',
        validTo: id ? offer?.validTo : '',
        navigationData: id && offer?.data?.navigationData ? JSON.parse(offer.data.navigationData) : '',
        experts,
      };
    },
    enableReinitialize: true,
    validationSchema: schema,
    handleSubmit: (values, { props }) => {
      const {
        expertId, title, imageUrl, price, ordinaryPrice, discount, affiliate, source, url, validTo, validFrom, navigationData,
      } = values;
      const {
        creteProductOfferRequest, updateProductOfferRequest, id, pathname,
      } = props;
      const data = {
        expertId,
        type: pathname.indexOf('createAnnons') >= 0 || pathname.indexOf('editAnnons') >= 0 ? ANNONS_TYPE : PRODUCT_OFFER_TYPE,
        name: title,
        validFrom: new Date(validFrom),
        validTo: new Date(validTo),
        data: {
          imageUrl,
          price: {
            price,
            ordinaryPrice,
            discount,
          },
          link: {
            affiliate,
            source,
            url,
          },
          navigationData: JSON.stringify(navigationData),
        },
      };
      if (id) {
        updateProductOfferRequest(data, expertId, id);
      } else {
        creteProductOfferRequest(data);
      }
    },
  }),
)(CreateProductForm);

export default CreateProductOfferFormik;
