import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import ArticleIcon from '@mui/icons-material/Article';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LoginIcon from '@mui/icons-material/Login';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { Typography } from '@material-ui/core';
import { AppDrawerHeight } from '../constants/common';
import { AppSettings } from '../settings/appSettings';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const useGetMenuItems = () => {
  const isAuthenticated = useSelector((state: any) => (state.auth.currentUser));

  const sections = [{
    path: '/experts',
    title: 'Experts',
    Icon: <BusinessIcon />,
    isAuthenticated,
  },
  {
    path: '/articles',
    title: 'Articles',
    Icon: <ArticleIcon />,
    isAuthenticated,
  },
  {
    path: '/listPropertyChecks',
    title: 'Property checks',
    Icon: <AddTaskIcon />,
    isAuthenticated,
  },
  ].filter((i) => i.isAuthenticated);

  const actions = [{
    path: 'login',
    title: 'Login',
    Icon: <LoginIcon />,
    isAuthenticated: !isAuthenticated,
  },
  {
    path: '/uploadDocument',
    title: 'Upload Document',
    Icon: <FileUploadIcon />,
    isAuthenticated,
  },
  {
    path: '/verifyProperty',
    title: 'Verify Property',
    Icon: <LibraryAddCheckIcon />,
    isAuthenticated,
  },
  ].filter((i) => i.isAuthenticated);

  return {
    actions,
    sections,
  };
};
const DrawerMenu = ({ open, onOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { actions, sections } = useGetMenuItems();

  const handleDrawerClose = () => onOpen(false);

  return (
    <Drawer
      sx={{
        width: AppDrawerHeight,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: AppDrawerHeight,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      { }
      <DrawerHeader>
        <Typography component="h1" variant="h6">
          {AppSettings.firebaseConfig?.projectId}
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {sections.map((item: any) => (
          <ListItem key={item.title} button onClick={() => navigate(item.path)}>
            <ListItemIcon>
              {item.Icon}
            </ListItemIcon>

            <ListItemText>{item.title}</ListItemText>
          </ListItem>
        ))}
      </List>
      {Boolean(sections.length) && <Divider />}
      <List>
        {actions.map((item: any) => (
          <ListItem key={item.title} button onClick={() => navigate(item.path)}>
            <ListItemIcon>
              {item.Icon}
            </ListItemIcon>

            <ListItemText>{item.title}</ListItemText>
          </ListItem>
        ))}
      </List>
      {Boolean(actions.length) && <Divider />}
    </Drawer>

  );
};

export default DrawerMenu;
