import * as React from 'react';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { LinearProgress } from '@material-ui/core';

const HIDDataGrid: React.FC<DataGridProps> = ({
  rows,
  columns,
  loading,
  checkboxSelection = false,
  components,
  rowCount,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => (
  <div style={{ display: 'flex', height: '100%' }}>
    <div style={{ flexGrow: 1 }}>
      <DataGrid
        disableSelectionOnClick
        page={page}
        pageSize={pageSize}
        rows={rows}
        columns={columns}
        loading={loading}
        rowCount={rowCount}
        checkboxSelection={checkboxSelection}
        components={{ LoadingOverlay: LinearProgress, ...components }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  </div>
);
export default HIDDataGrid;
