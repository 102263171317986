/* eslint-disable @typescript-eslint/default-param-last */
// @ts-nocheck

import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
  createProductOffer, fetchProductOffers, updateProductOffer, deleteProductOffer,
} from '../api/productOffer';
import { ProductOffer, ProductOfferFormatted, ANNONS_TYPE } from '../../../types/productOfferProperties';

import { addProductOfferItem, deleteProductOfferItem, updateProductOfferItem } from '../../../services/productOffer';
import { ActionError, ResponseData } from '../../../types/common';
import { showActionErrorAlert } from '../../../store/reducers/alerts';

export const CREATE_PRODUCT_OFFER_FOR_EXPERT_REQUEST = 'CREATE_PRODUCT_OFFER_FOR_EXPERT_REQUEST';
export const CREATE_PRODUCT_OFFER_FOR_EXPERT_SUCCESS = 'CREATE_PRODUCT_OFFER_FOR_EXPERT_SUCCESS';
export const CREATE_PRODUCT_OFFER_FOR_EXPERT_FAILED = 'CREATE_PRODUCT_OFFER_FOR_EXPERT_FAILED';

export const FETCH_PRODUCT_OFFERS_REQUEST = 'FETCH_PRODUCT_OFFERS_REQUEST';
export const FETCH_PRODUCT_OFFERS_SUCCESS = 'FETCH_PRODUCT_OFFERS_SUCCESS';
export const FETCH_PRODUCT_OFFERS_FAILED = 'FETCH_PRODUCT_OFFERS_FAILED';

export const UPDATE_PRODUCT_OFFER_REQUEST = 'UPDATE_PRODUCT_OFFER_REQUEST';
export const UPDATE_PRODUCT_OFFER_SUCCESS = 'UPDATE_PRODUCT_OFFER_SUCCESS';
export const UPDATE_PRODUCT_OFFER_FAILED = 'UPDATE_PRODUCT_OFFER_FAILED';

const DELETE_PRODUCT_OFFER_REQUEST = 'DELETE_PRODUCT_OFFER_REQUEST';
const DELETE_PRODUCT_OFFER_SUCCESS = 'DELETE_PRODUCT_OFFER_SUCCESS';
const DELETE_PRODUCT_OFFER_FAILED = 'DELETE_PRODUCT_OFFER_FAILED';

const initState = {
  loading: false,
  isLoaded: false,
  error: '',
  productOffer: null,
  productOffers: [],
  isAnnons: false,
};

export interface ProductOfferState {
  loading: boolean,
  isLoaded: boolean,
  error: string,
  productOffer: | null,
  productOffers: Array<ProductOffer>,
  isAnnons: boolean,
}

interface ProductOfferStateAction {
  type: string;
  data: any,
  error?: ActionError;
  expertId: string,
  id: string,
}

export const productOffersReducer = (
  state: ProductOfferState = initState,
  action: ProductOfferStateAction,
) => {
  switch (action.type) {
    case CREATE_PRODUCT_OFFER_FOR_EXPERT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        isAnnons: action.data.type === ANNONS_TYPE,
      };
    case CREATE_PRODUCT_OFFER_FOR_EXPERT_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        productOffer: action.data,
        productOffers: addProductOfferItem(state.productOffers, action.data),
      };
    }
    case CREATE_PRODUCT_OFFER_FOR_EXPERT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case FETCH_PRODUCT_OFFERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_PRODUCT_OFFERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        productOffers: action.data,
      };
    }
    case FETCH_PRODUCT_OFFERS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case UPDATE_PRODUCT_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case UPDATE_PRODUCT_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        productOffers: updateProductOfferItem(state.productOffers, action.data),
      };
    }
    case UPDATE_PRODUCT_OFFER_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_PRODUCT_OFFER_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case DELETE_PRODUCT_OFFER_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        productOffers: deleteProductOfferItem(state.productOffers, action.data),
      };
    }
    case DELETE_PRODUCT_OFFER_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: return state;
  }
};

export const creteProductOfferRequest = (data: ProductOfferFormatted) => ({
  type: CREATE_PRODUCT_OFFER_FOR_EXPERT_REQUEST,
  data,
});

export const fetchProductOffersRequest = (expertId: string) => ({
  type: FETCH_PRODUCT_OFFERS_REQUEST,
  expertId,
});

export const updateProductOfferRequest = (data: ProductOfferFormatted, expertId: string, id: string) => ({
  type: UPDATE_PRODUCT_OFFER_REQUEST,
  data,
  expertId,
  id,
});

export const deleteProductOfferRequest = (expertId: string, id: string) => ({
  type: DELETE_PRODUCT_OFFER_REQUEST,
  expertId,
  id,
});

export function* createProductOfferSaga(productOffer: ProductOfferStateAction): Generator {
  try {
    const productOfferResponse: ResponseData<ProductOffer> = yield call(createProductOffer, productOffer.data, productOffer.data.expertId);
    yield put(({
      type: CREATE_PRODUCT_OFFER_FOR_EXPERT_SUCCESS,
      data: productOfferResponse.data,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: CREATE_PRODUCT_OFFER_FOR_EXPERT_FAILED,
      error,
    }));
  }
}

export function* fetchProductOffersSaga(productOfferAction: ProductOfferStateAction): Generator {
  try {
    const response: ResponseData<Array<ProductOffer>> = yield call(fetchProductOffers, productOfferAction.expertId);
    yield put(({
      type: FETCH_PRODUCT_OFFERS_SUCCESS,
      data: response.data,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: FETCH_PRODUCT_OFFERS_FAILED,
      error,
    }));
  }
}

export function* updateProductOfferSaga(productOfferAction: ProductOfferStateAction): Generator {
  const { data, expertId, id } = productOfferAction;
  try {
    const productOffersResponse = yield call(updateProductOffer, data, expertId, id);
    yield put(({
      type: UPDATE_PRODUCT_OFFER_SUCCESS,
      data: productOffersResponse,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: UPDATE_PRODUCT_OFFER_FAILED,
      error,
    }));
  }
}

export function* deleteProductOfferSaga(productOfferAction: ProductOfferStateAction): Generator {
  const { expertId, id } = productOfferAction;
  try {
    // @ts-ignore
    yield call(deleteProductOffer, expertId, id);
    yield put(({
      type: DELETE_PRODUCT_OFFER_SUCCESS,
      data: id,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: DELETE_PRODUCT_OFFER_FAILED,
      error,
    }));
  }
}

function* watchProductOffer() {
  yield takeLatest(CREATE_PRODUCT_OFFER_FOR_EXPERT_REQUEST, createProductOfferSaga);
  yield takeLatest(FETCH_PRODUCT_OFFERS_REQUEST, fetchProductOffersSaga);
  yield takeLatest(UPDATE_PRODUCT_OFFER_REQUEST, updateProductOfferSaga);
  yield takeLatest(DELETE_PRODUCT_OFFER_REQUEST, deleteProductOfferSaga);
}

export function* productOfferSaga() {
  yield all([watchProductOffer()]);
}
