/* eslint-disable @typescript-eslint/default-param-last */
// @ts-nocheck
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { verifyProperty, searchUCAddress } from '../api/verifyProperty';
import { ResponseData } from '../../../types/common';
import { showActionErrorAlert, showSuccessAlert } from '../../../store/reducers/alerts';
import { UCAddress } from '../../../types/verifyPropertyProperties';

export const SEARCH_UC_ADDRESS_REQUEST = 'SEARCH_UC_ADDRESS_REQUEST';
export const SEARCH_UC_ADDRESS_SUCCESS = 'SEARCH_UC_ADDRESS_SUCCESS';
export const SEARCH_UC_ADDRESS_FAILED = 'SEARCH_UC_ADDRESS_FAILED';

export const VERIFY_PROPERTY_REQUEST = 'VERIFY_PROPERTY_REQUEST';
export const VERIFY_PROPERTY_SUCCESS = 'VERIFY_PROPERTY_SUCCESS';
export const VERIFY_PROPERTY_FAILED = 'VERIFY_PROPERTY_FAILED';

const initState = {
  addresses: [],
  addressesLoading: false,
  addressesError: '',
  verifyingPropertyError: '',
  isVerifyingProperty: false,
};

export interface VerifyPropertyState {
  addressesLoading: boolean,
  isVerifyingProperty: boolean,
  addressesError: string,
  verifyingPropertyError: string,
  addresses: Array<UCAddress>,
}

interface VerifyData { type: string, objectId: string, apartmentNumber?: number }

interface VerifyPropertyStateAction {
  type: string,
  addresses: Array<UCAddress>,
  searchQuery: string,
  error: any | null,
  data: VerifyData,
  propertyId: string,
  result: any,
}

export const verifyPropertyReducer = (
  state: VerifyPropertyState = initState,
  action: VerifyPropertyStateAction,
) => {
  switch (action.type) {
    case SEARCH_UC_ADDRESS_REQUEST:
      return {
        ...state,
        addressesLoading: true,
        addressesError: '',
      };
    case SEARCH_UC_ADDRESS_SUCCESS: {
      return {
        ...state,
        addressesLoading: false,
        addresses: action.addresses || [],
        query: action.query,
      };
    }
    case SEARCH_UC_ADDRESS_FAILED: {
      return {
        ...state,
        addressesLoading: false,
        addressesError: action.error,
      };
    }
    case VERIFY_PROPERTY_REQUEST:
      return {
        ...state,
        isVerifyingProperty: true,
        verifyingPropertyError: '',
      };
    case VERIFY_PROPERTY_SUCCESS: {
      return {
        ...state,
        isVerifyingProperty: false,
      };
    }
    case VERIFY_PROPERTY_FAILED: {
      return {
        ...state,
        isVerifyingProperty: false,
        verifyingPropertyError: action.error,
      };
    }
    default: return state;
  }
};

export const searchUCAddressRequest = (searchQuery: string) => ({
  type: SEARCH_UC_ADDRESS_REQUEST,
  searchQuery,
});

export const verifyPropertyRequest = (propertyId: string, data: VerifyData) => ({
  type: VERIFY_PROPERTY_REQUEST,
  propertyId,
  data,
});

export function* searchUCAddressSaga({ searchQuery }: VerifyPropertyStateAction): Generator {
  try {
    const { data }: ResponseData<Array<UCAddress>> = yield call(searchUCAddress, searchQuery);
    yield put(({
      type: SEARCH_UC_ADDRESS_SUCCESS,
      addresses: data,
      query: searchQuery,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: SEARCH_UC_ADDRESS_FAILED,
      error,
    }));
  }
}

export function* verifyCurrentPropertySaga({ propertyId, data }: VerifyPropertyStateAction): Generator {
  try {
    yield call(verifyProperty, propertyId, data);
    yield put(({
      type: VERIFY_PROPERTY_SUCCESS,
    }));
    yield put(showSuccessAlert('Property successfully verified!'));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: VERIFY_PROPERTY_FAILED,
      error,
    }));
  }
}

function* watchVerifyProperty() {
  yield takeLatest(SEARCH_UC_ADDRESS_REQUEST, searchUCAddressSaga);
  yield takeLatest(VERIFY_PROPERTY_REQUEST, verifyCurrentPropertySaga);
}

export function* verifyPropertySaga() {
  yield all([watchVerifyProperty()]);
}
