import { withFormik } from 'formik';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import {
  createArticleRequest,
  fetchCategoriesRequest,
  updateArticleRequest,
  requestCreateDynamicLink,
  fetchArticleRequest,
  copyArticlesGeneratedLinkRequest,
} from '../../../reducers/articles';
import {
  ArticleProps, ArticleType, FormValues, MyFormProps,
} from '../../../types/articles';
import { createExpertArticlesRequest } from '../../../../Experts/reducers/experts';
import InnerForm from './createArticleForm';

const schema = Yup.object().shape({
  title: Yup.string().required('Article title is missing').min(3, 'Title too short'),
  description: Yup.string().required('Article description is missing').min(3, 'Description too short').max(1024, 'Description is too long'),
  image: Yup.string().required('Image link missing').max(256, 'Url is too long'),
  link: Yup.string().required('Link for article is missing'),
  sourceUrl: Yup.string().required('Source url is missing'),
  sourceName: Yup.string().required('Source name is missing'),
  articleCategories: Yup.array().min(1, 'Categories missing'),
});

const CreateArticleForm = compose(
  connect(
    ({ articles, experts }: any) => ({
      articles: articles.articles,
      experts: experts.experts,
      categories: articles.categories,
      articlesPage: articles.pageNumber,
      articlesLoading: articles.loading,
      article: articles.currentArticle,
      dynamicLinks: articles.dynamicLinks,
      articlesError: articles.error,
      isGenerateLinkRequestSuccess: articles.isGenerateLinkRequestSuccess,
      isGeneratedLinkCopied: articles.isGeneratedLinkCopied,
      isDynamicLinkLoading: articles.isDynamicLinkLoading,
    }),
    {
      createArticleRequest,
      fetchCategoriesRequest,
      updateArticleRequest,
      requestCreateDynamicLink,
      createExpertArticlesRequest,
      fetchArticleRequest,
      copyArticlesGeneratedLinkRequest,
    },
  ),
  withFormik<ArticleProps & MyFormProps, FormValues>({
    mapPropsToValues: (props: any) => {
      const {
        id, categories, pathname, article, dynamicLinks,
      } = props;
      // eslint-disable-next-line no-console
      return {
        id: id ? article?.id : '',
        title: id ? article?.title : '',
        description: id ? article?.ingress : '',
        image: id ? article?.imageUrl : '',
        link: id ? article?.data?.link : '',
        sourceUrl: id ? article?.source?.url : '',
        sourceName: id ? article?.source?.name : '',
        articleCategories: pathname.indexOf('createNews') >= 0 || pathname.indexOf('editNews') >= 0
          ? [{ id: 'houseid.se/article/category/news' }] : id ? article?.classifications : [],
        language: id ? article?.language : 'sv',
        dynamicLink: id ? dynamicLinks.find((dynamicLink: any) => dynamicLink.id === id)?.link : '',
        articleExperts: id ? article?.articleExperts : [],
        type: pathname.indexOf('createNews') >= 0 || pathname.indexOf('editNews') >= 0 ? ArticleType.NEWS : ArticleType.LINK,
        inspirationsList: id ? article?.data?.inspirationsList === undefined || article?.data?.inspirationsList : pathname.indexOf('/experts') < 0,
        expertsList: id ? article?.data?.expertsList === undefined || article?.data?.expertsList : pathname.indexOf('/experts') >= 0,
        categories,
      };
    },
    enableReinitialize: true,
    validationSchema: schema,
    handleSubmit: async (values, { props }) => {
      const {
        pathname, id, expertId, createArticleRequest, updateArticleRequest,
      } = props;
      const data = {
        type: pathname.indexOf('createNews') >= 0 || pathname.indexOf('editNews') >= 0 ? ArticleType.NEWS : ArticleType.LINK,
        language: values.language,
        publishDate: new Date().toISOString(),
        title: values.title,
        ingress: values.description,
        imageUrl: values.image,
        data: {
          link: values.link?.trim(),
          inspirationsList: values.inspirationsList,
          expertsList: values.expertsList,
        },
        source: {
          name: values.sourceName,
          url: values.sourceUrl,
        },
        classifications: values.articleCategories,
        articleExperts: values.articleExperts,
      };
      if (id) {
        updateArticleRequest(data, props.id, expertId);
      } else {
        createArticleRequest(data, expertId);
      }
    },
  }),
)(InnerForm);

export default CreateArticleForm;
