export interface FormValues {
  id: string,
  title?: string,
  description?: string,
  image?: string,
  link?: string,
  sourceUrl?: string,
  sourceName?: string,
  articleCategories?: Array<string>,
  language: string,
  dynamicLink: any,
  articleExperts: Array<any>,
  type: string,
  inspirationsList?: boolean,
  expertsList?: boolean,
}

export interface MyFormProps {
  createArticleRequest: any,
  fetchCategoriesRequest: any,
  categories: any,
  updateArticleRequest: any,
  articles: any,
  requestCreateDynamicLink: any,
  experts: Array<any>,
  fetchExpertsRequest: () => void,
  pathname: string,
  createExpertArticlesRequest: (expertId: string, data: any, articleType: string) => void,
  fetchArticleRequest: (id: string) => void,
  articlesPage: number,
  articlesLoading: boolean,
  article: any,
  id: string,
  articlesError: any,
  isGenerateLinkRequestSuccess: boolean,
  isGeneratedLinkCopied: boolean,
  copyArticlesGeneratedLinkRequest: any,
  isDynamicLinkLoading: boolean,
}

export interface ArticleProps {
  id: string,
  articles: any,
  categories: any,
  experts: any,
  pathname: string,
  expertId: string,
  article: any,
}

export interface Category {
  id: string,
  name: string,
  iconUrl: string,
}

export enum ArticleType {
  LINK = 'link',
  NEWS = 'news',
}
