import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { createBlobRequest } from '../../api/blob';
import { ActionError } from '../../types/common';
import { showActionErrorAlert } from './alerts';

export const CREATE_BLOB_REQUEST = 'CREATE_BLOB_REQUEST';
export const CREATE_BLOB_REQUEST_SUCCESS = 'CREATE_BLOB_REQUEST_SUCCESS';
export const CREATE_BLOB_REQUEST_FAILED = 'CREATE_BLOB_REQUEST_FAILED';

const initState = {
  loading: false,
  error: null,
  blobIds: [],
};

export const blobsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case CREATE_BLOB_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case CREATE_BLOB_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        blobIds: action.data.flatMap((item: any) => item.data.blobs.map((blob: any) => blob.id)),
        error: null,
      };
    }
    case CREATE_BLOB_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const requestCreateBlob = (data: any): any => ({ type: CREATE_BLOB_REQUEST, data } as any);

export const createBlobSuccess = (data: any): any => ({ type: CREATE_BLOB_REQUEST_SUCCESS, data });

export const createBlobFailure = (error: ActionError): any => ({ type: CREATE_BLOB_REQUEST_FAILED, error });

export function* createBlobSaga(data: any): Generator {
  const { files } = data.data;
  try {
    const responses = yield all(files.map((file: any) => (call(createBlobRequest, { propertyId: data.data.propertyId, file }))));
    yield put(createBlobSuccess(responses));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(createBlobFailure(error.message));
  }
}

function* watchCreateBlobRequest() {
  yield takeLatest(CREATE_BLOB_REQUEST, createBlobSaga);
}

export function* blobSaga() {
  yield all([watchCreateBlobRequest()]);
}
