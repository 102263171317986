import { withFormik } from 'formik';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { loginUser } from '../../reducers/auth';
import InnerForm from './InnerForm';

interface FormValues {
  email: string;
  password: string;
}

interface MyFormProps {
  loginUser?: any;
  isAdmin?: boolean;
  loginError?: string;
}

const schema = Yup.object().shape({
  email: Yup.string().required('email required'),
  password: Yup.string().required('password required').min(4, 'Password is too short - should be 8 chars minimum.'),
  loginError: Yup.string().length(0),
});

const LoginForm = compose(
  connect(((state: any) => ({ isAdmin: state.auth.isAdmin, loginError: state.auth.error })), { loginUser }),
  withFormik<MyFormProps, FormValues>({
    mapPropsToValues: ({ loginError, isAdmin }) => ({
      email: '', password: '', loginError, isAdmin,
    }),
    validationSchema: schema,

    handleSubmit: async (values, { props }) => {
      const { loginUser } = props;
      loginUser({ email: values.email, password: values.password });
    },
  }),
)(InnerForm);

export default LoginForm;
