// @ts-nocheck

import React from 'react';
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import ListExperts from '../modules/Experts/pages/ListExperts/ListExperts';
import CreateExpert from '../modules/Experts/pages/CreateExpert';
import ListArticles from '../modules/Articles/pages/ListArticles/ListArticles';
import CreateArticle from '../modules/Articles/pages/CreateArticle';
import UploadDocument from '../modules/Actions/pages/UploadDocumentToProperty/index';
import CreateProductOffer from '../modules/Experts/pages/CreateProductOffer';
import LoginForm from '../modules/User/pages/login';
import VerifyPropertyFormik from '../modules/Actions/pages/VerifyProperty';
import AddPropertyCheckRequest from '../modules/PropertyChecks/pages/AddPropertyCheckRequest/AddPropertyCheckRequest';
import ListPropertyChecks from '../modules/PropertyChecks/pages/ListPropertyChecks/ListPropertyChecks';

const routesMapArray = [
  {
    path: '/experts',
    component: <ListExperts />,
  },
  {
    path: '/experts/create',
    component: <CreateExpert />,
  },
  {
    path: '/experts/edit/:id',
    component: <CreateExpert />,
  },
  {
    path: '/experts/:expertId/createProductOffer',
    component: <CreateProductOffer />,
  },
  {
    path: '/experts/:expertId/editProductOffer/:id',
    component: <CreateProductOffer />,
  },
  {
    path: '/experts/:expertId/createAnnons',
    component: <CreateProductOffer />,
  },
  {
    path: '/experts/:expertId/editAnnons/:id',
    component: <CreateProductOffer />,
  },
  {
    path: '/experts/:expertId/createExpertArticle',
    component: <CreateArticle />,
  },
  {
    path: '/experts/:expertId/editExpertArticle/:id',
    component: <CreateArticle />,
  },
  {
    path: '/experts/:expertId/createNews',
    component: <CreateArticle />,
  },
  {
    path: '/experts/:expertId/editNews/:id',
    component: <CreateArticle />,
  },
  {
    path: '/articles',
    component: <ListArticles />,
  },
  {
    path: '/createArticle',
    component: <CreateArticle />,
  },
  {
    path: '/editArticle/:id/',
    component: <CreateArticle />,
  },
  {
    path: '/uploadDocument',
    component: <UploadDocument />,
  },
  {
    path: '/verifyProperty',
    component: <VerifyPropertyFormik />,
  },
  {
    path: '/addPropertyCheckRequest',
    component: <AddPropertyCheckRequest />,
  },
  {
    path: '/listPropertyChecks',
    component: <ListPropertyChecks />,
  },
];

export default function MainRouter() {
  const currentUser = useSelector((state: any) => (state.auth.currentUser));
  const initialRoute = currentUser ? '/articles' : '/login';

  return (
    <Routes>
      {!currentUser && <Route path="/login" element={<LoginForm />} />}

      {currentUser && routesMapArray.map((item: any) => (
        <Route key={item.path} exact path={item.path} element={item.component} />
      ))}
      <Route
        path="*"
        element={<Navigate to={initialRoute} replace />}
      />
    </Routes>
  );
}
