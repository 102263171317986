// @ts-nocheck
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

interface CustomGridProperties {
  className: string,
  columns: Array<any>,
  rows: Array<any>,
  pageSize: number,
  onRowDoubleClick?: (e: any) => void
  checkboxSelection?: boolean,
}

function CustomGrid({
  className, columns, rows, pageSize, onRowDoubleClick, checkboxSelection,
}: CustomGridProperties) {
  return rows.length > 0 ? (
    <DataGrid
      checkboxSelection={checkboxSelection}
      className={className}
      columns={columns}
      disableSelectionOnClick
      onRowDoubleClick={onRowDoubleClick}
      pageSize={pageSize}
      rows={rows}
      style={{ marginLeft: 20, marginTop: 20 }}
    />
  ) : <div />;
}

export default CustomGrid;
