import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ErrorBoundary } from 'react-error-boundary';
import { useEffect } from 'react';
import DrawerMenu from './navigation/DrawerMenu';
import MainRouter from './navigation/MainRouter';
import CustomAppBar from './components/common/CustomAppBar';
import { AppBarHeight, AppDrawerHeight } from './constants/common';
import CustomSnackbar from './components/common/CustomSnackbar';
import { showErrorAlert } from './store/reducers/alerts';
import ErrorPage from './components/ErrorPage';
import { loginUserByToken } from './modules/User/reducers/auth';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  paddingTop: AppBarHeight,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${AppDrawerHeight}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function App() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const myErrorHandler = (error: Error) => dispatch(showErrorAlert(error.message));

  useEffect(() => {
    dispatch(loginUserByToken());
  }, []);

  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex' }}>
        <CustomAppBar open={open} onOpen={setOpen} />
        <DrawerMenu open={open} onOpen={setOpen} />
        <Main open={open}>
          <ErrorBoundary onError={myErrorHandler} FallbackComponent={ErrorPage}>
            <MainRouter />
          </ErrorBoundary>

        </Main>
      </Box>
      <CustomSnackbar />
    </BrowserRouter>
  );
}
