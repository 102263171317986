import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usePrevious from './usePrevious';

type UseOnStateChange = (
  stateSelector: any,
  callback: () => void,
  stateAfterChange?: any,
) => () => void;

const useOnStateChange: UseOnStateChange = (stateSelector, callback, stateAfterChange = false) => {
  const state = useSelector(stateSelector);
  const prevState = usePrevious(state);

  const [triggerAfterChange, setTriggerAfterChange] = useState(false);

  useEffect(() => {
    if (triggerAfterChange && state !== prevState && state === stateAfterChange) {
      callback();
    }
  }, [setTriggerAfterChange, state]);
  return () => setTriggerAfterChange(true);
};

export default useOnStateChange;
