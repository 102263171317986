import { withFormik } from 'formik';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Expert } from '../../../../../types/entities';
import { fetchArticlesRequest, fetchNewsRequest } from '../../../../Articles/reducers/articles';
import {
  fetchExpertsCategoriesRequest,
  createExpertRequest,
  updateExpertRequest,
  fetchExpertArticlesRequest,
  createExpertArticlesRequest,
  deleteExpertArticleRequest,
  fetchExpertsRequest,
  requestCreateExpertsDynamicLink,
  copyGeneratedLinkRequest,
} from '../../../reducers/experts';
import { fetchProductOffersRequest, deleteProductOfferRequest } from '../../../reducers/productOffer';
import { ExpertProps, MyFormProps } from '../../../types/experts';
import InnerForm from './CreateExpertForm';

const schema = Yup.object().shape({
  name: Yup.string().required('Field shouldn\'t be empty'),
  description: Yup.string().required('Field shouldn\'t be empty').max(2048, 'Description is too long'),
  streetAddress: Yup.string().required('Field shouldn\'t be empty'),
  postalCode: Yup.string().nullable(),
  addressLocality: Yup.string().required('Field shouldn\'t be empty'),
  addressCountry: Yup.string().required('Field shouldn\'t be empty'),
  website: Yup.string().nullable(),
  email: Yup.string().required('Field shouldn\'t be empty'),
  phone: Yup.string().nullable(),
  expertCategories: Yup.array().min(1, 'Categories missing'),
  url: Yup.string().required('Field shouldn\'t be empty'),
  logoUrl: Yup.string().nullable(),
  longitude: Yup.number().required('Field shouldn\'t be empty').nullable(),
  latitude: Yup.number().required('Field shouldn\'t be empty').nullable(),
});

const getExpertById = (id: string, experts: Array<Expert>) => experts.find((expert: Expert) => expert.id === id);

const CreateArticleForm = compose(
  connect(
    ({ experts, articles, productOffers }: any) => ({
      categories: experts.categories.map((category: any) => category.subCategories).flat(),
      experts: experts.experts,
      articles: articles.articles,
      productOffers: productOffers.productOffers,
      articlesPage: articles.pageNumber,
      expertArticles: experts.articles,
      news: articles.news,
      createdNews: articles.createdNews,
      newsPage: articles.newsPage,
      createdArticle: articles.createdArticle,
      productOffersLoading: productOffers.loading,
      articlesLoading: articles.loading,
      isNews: articles.isNews,
      isAnnons: productOffers.isAnnons,
      expertsPage: experts.pageNumber,
      expertLoading: experts.loading,
      expertError: experts.error,
      dynamicLinks: experts.dynamicLinks,
      isGenerateLinkRequestSuccess: experts.isGenerateLinkRequestSuccess,
      isGeneratedLinkCopied: experts.isGeneratedLinkCopied,
      isDynamicLinkLoading: experts.isDynamicLinkLoading,
    }),
    {
      fetchExpertsCategoriesRequest,
      createExpertRequest,
      updateExpertRequest,
      fetchArticlesRequest,
      fetchProductOffersRequest,
      deleteProductOfferRequest,
      fetchExpertArticlesRequest,
      createExpertArticlesRequest,
      deleteExpertArticleRequest,
      fetchNewsRequest,
      fetchExpertsRequest,
      requestCreateExpertsDynamicLink,
      copyGeneratedLinkRequest,
    },
  ),
  withFormik<ExpertProps & MyFormProps, any>({
    mapPropsToValues: (props: any) => {
      const {
        id, experts, productOffers, expertArticles, dynamicLinks,
      } = props;
      const expert = getExpertById(id, experts);
      return {
        name: id ? expert?.place.name : '',
        streetAddress: id ? expert?.place.address.streetAddress : '',
        postalCode: id ? expert?.place.address.postalCode : '',
        addressLocality: id ? expert?.place.address.addressLocality : '',
        addressCountry: id ? expert?.place.address.addressCountry : 'SE',
        website: id ? expert?.place.contactDetails.website : '',
        email: id ? expert?.place.contactDetails.email : '',
        phone: id ? expert?.place.contactDetails.phone : '',
        expertCategories: id ? expert?.classifications : [],
        description: id ? expert?.description : '',
        url: id ? expert?.url : '',
        logoUrl: id ? expert?.logoUrl : '',
        // @ts-ignore
        longitude: id ? expert?.place?.geoCoordinate?.coordinates[0] : null,
        // @ts-ignore
        latitude: id ? expert?.place?.geoCoordinate?.coordinates[1] : null,
        dynamicLink: id ? dynamicLinks.find((dynamicLink: any) => dynamicLink.id === id)?.link : {},
        expertArticles: id ? expertArticles.flatMap((itm: any) => itm[id] || []) : [],
        offers: id ? productOffers?.filter(({ expertId }: any) => expertId === id) : [],
        id,
      };
    },
    enableReinitialize: true,
    validationSchema: schema,
    handleSubmit: (values, { props }) => {
      const data = {
        classifications: values.expertCategories,
        description: values.description,
        url: values.url,
        place: {
          type: 'company',
          name: values.name,
          address: {
            streetAddress: values.streetAddress,
            postalCode: values.postalCode,
            addressLocality: values.addressLocality,
            addressCountry: values.addressCountry,
          },
          contactDetails: {
            website: values.website,
            email: values.email,
            phone: values.phone,
          },
          geoCoordinate: {
            type: 'Point',
            coordinates: [
              values.longitude,
              values.latitude,
            ],
          },
        },
        logoUrl: values.logoUrl,
      };
      if (props.id) {
        props.updateExpertRequest(props.id, data);
      } else {
        props.createExpertRequest(data);
      }
    },
  }),
)(InnerForm);

export default CreateArticleForm;
