/* eslint-disable @typescript-eslint/default-param-last */
// @ts-nocheck
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { AnyAction } from 'redux';
import _ from 'lodash';
import { ActionError, ResponseData } from '../../../types/common';
import { showActionErrorAlert, showSuccessAlert } from '../../../store/reducers/alerts';
import { AddPropertyCheckRequestData } from '../types/propertyCheck';
import { propertyCheckRequest, fetchPropertyChecks } from '../api/propertyCheck';

export const ADD_PROPERTY_CHECK_REQUEST_REQUEST = 'ADD_PROPERTY_CHECK_REQUEST_REQUEST';
export const ADD_PROPERTY_CHECK_REQUEST_SUCCESS = 'ADD_PROPERTY_CHECK_REQUEST_SUCCESS';
export const ADD_PROPERTY_CHECK_REQUEST_FAILED = 'ADD_PROPERTY_CHECK_REQUEST_FAILED';

export const FETCH_PROPERTY_CHECKS_REQUEST = 'FETCH_PROPERTY_CHECKS_REQUEST';
export const FETCH_PROPERTY_CHECKS_SUCCESS = 'FETCH_PROPERTY_CHECKS_SUCCESS';
export const FETCH_PROPERTY_CHECKS_FAILED = 'FETCH_PROPERTY_CHECKS_FAILED';

const initState = {
  propertyCheckLoading: false,
  propertyCheckError: null,
  propertyChecks: [],
  propertyChecksLoading: false,
  propertyChecksError: null,
};

export interface PropertyCheckState {
  propertyCheckLoading: boolean,
  propertyCheckError?: ActionError,
  propertyChecks: Array,
  propertyChecksLoading: boolean,
  propertyChecksError?: ActionError,
}

export const propertyCheckReducer = (
  state: PropertyCheckState = initState,
  action: AnyAction,
) => {
  switch (action.type) {
    case ADD_PROPERTY_CHECK_REQUEST_REQUEST:
      return {
        ...state,
        propertyCheckLoading: true,
        propertyCheckError: undefined,
      };
    case ADD_PROPERTY_CHECK_REQUEST_SUCCESS: {
      return {
        ...state,
        propertyCheckLoading: false,
      };
    }
    case ADD_PROPERTY_CHECK_REQUEST_FAILED: {
      return {
        ...state,
        propertyCheckLoading: false,
        propertyCheckError: action.error,
      };
    }
    case FETCH_PROPERTY_CHECKS_REQUEST: {
      return {
        ...state,
        propertyChecksLoading: true,
        propertyChecksError: undefined,
      };
    }
    case FETCH_PROPERTY_CHECKS_SUCCESS: {
      return {
        ...state,
        propertyChecksLoading: false,
        propertyChecks: [...state.propertyChecks, ...action.propertyChecks],
      };
    }
    case FETCH_PROPERTY_CHECKS_FAILED: {
      return {
        ...state,
        propertyChecksLoading: false,
        propertyChecksError: action.error,
      };
    }
    default: return state;
  }
};

export const submitPropertyCheckRequest = (
  data: AddPropertyCheckRequestData,
): AnyAction => ({ type: ADD_PROPERTY_CHECK_REQUEST_REQUEST, data } as AnyAction);

export function* propertyCheckRequestSaga({ data }: { AddPropertyCheckRequestData }): Generator {
  try {
    const mappedData = _.omit(data, ['streetAddress', 'postalCode', 'addressLocality', 'addressCountry']);

    yield call(propertyCheckRequest, {
      ...mappedData,
      address: {
        streetAddress: data.streetAddress,
        postalCode: data.postalCode,
        addressLocality: data.addressLocality,
        addressCountry: data.addressCountry,
      },
    });
    yield put(({
      type: ADD_PROPERTY_CHECK_REQUEST_SUCCESS,
    }));
    yield put(showSuccessAlert('Property check request has been successfully submitted'));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: ADD_PROPERTY_CHECK_REQUEST_FAILED,
      error,
    }));
  }
}

export function* fetchPropertyChecksSaga(): Generator {
  try {
    // @ts-ignore
    const response: ResponseData<Object> = yield call(fetchPropertyChecks);
    yield put({ type: FETCH_PROPERTY_CHECKS_SUCCESS, propertyChecks: response.data.propertyChecks });
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put({ type: FETCH_PROPERTY_CHECKS_FAILED, error });
  }
}

function* watchPropertyCheckRequest() {
  yield takeLatest(ADD_PROPERTY_CHECK_REQUEST_REQUEST, propertyCheckRequestSaga);
  yield takeLatest(FETCH_PROPERTY_CHECKS_REQUEST, fetchPropertyChecksSaga);
}

export function* propertyCheckSaga() {
  yield all([watchPropertyCheckRequest()]);
}

export const fetchPropertyChecksRequest = (): AnyAction =>
  ({ type: FETCH_PROPERTY_CHECKS_REQUEST } as AnyAction);
