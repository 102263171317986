import { AnyAction } from 'redux';
import {
  put, takeLatest, all,
} from 'redux-saga/effects';
import { createGuid } from '../../services/utils/uuid';
import { ActionError } from '../../types/common';

const SHOW_ALERT = 'SHOW_ALERT';
const SHOW_ALERT_SUCCESS = 'SHOW_ALERT_SUCCESS';
const HIDE_ALERT = 'HIDE_ALERT';
const HIDE_ALERT_SUCCESS = 'HIDE_ALERT_SUCCESS';

const formatActionError = (error: ActionError) => {
  const statusCodePart = error.statusCode ? `${error.statusCode}: ` : '';
  const messagePart = error.message ? error.message : 'Something went wrong';
  const codePart = error.message ? `(${error.code})` : '';
  return `${statusCodePart}${messagePart}${codePart}`;
};

export type AlertData = {
  id?: string;
  message: string;
  alertType: 'success' | 'info' | 'warning' | 'error'
};

export interface AlertsState {
  alerts?: Array<AlertData>
}
export interface AlertStateAction {
  alert: AlertData
  type: string;
}

const initState = {
  alerts: [],
};

export const alertsReducer = (
  state: AlertsState = initState,
  action: AnyAction,
) => {
  switch (action.type) {
    case SHOW_ALERT:
    case HIDE_ALERT:
      return state;
    case SHOW_ALERT_SUCCESS:
      return {
        ...state,
        alerts: [...state.alerts, action.alert],
      };
    case HIDE_ALERT_SUCCESS:
      return {
        ...state,
        alerts: state.alerts.filter((a) => a.id !== action.alert.id),
      };
    default:
      return state;
  }
};

export const showSuccessAlert = (
  message: string,
): AlertStateAction => ({
  type: SHOW_ALERT,
  alert: {
    id: createGuid(),
    message,
    alertType: 'success',
  },
});

export const showErrorAlert = (
  message: string,
): AlertStateAction => ({
  type: SHOW_ALERT,
  alert: {
    id: createGuid(),
    message,
    alertType: 'error',
  },
});

export const showActionErrorAlert = (
  error: ActionError,
): AlertStateAction => ({
  type: SHOW_ALERT,
  alert: {
    id: createGuid(),
    message: formatActionError(error),
    alertType: 'error',
  },
});

export const showAlert = (
  alertData: AlertData,
): AlertStateAction => ({ type: SHOW_ALERT, alert: alertData });

export const hideAlert = (
  alertData: AlertData,
): AlertStateAction => ({ type: HIDE_ALERT, alert: alertData });

export function* showAlertSaga(alertStateAction: AlertStateAction): Generator {
  yield put({
    type: SHOW_ALERT_SUCCESS,
    alert: alertStateAction.alert,
  });
}

export function* hideAlertSaga(alertStateAction: AlertStateAction): Generator {
  yield put({
    type: HIDE_ALERT_SUCCESS,
    alert: alertStateAction.alert,
  });
}

function* watchShowAlert() {
  yield takeLatest(SHOW_ALERT, showAlertSaga);
}

function* watchHideAlert() {
  yield takeLatest(HIDE_ALERT, hideAlertSaga);
}

export function* alertsSaga() {
  yield all([
    watchShowAlert(),
    watchHideAlert(),
  ]);
}
