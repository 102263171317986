import { Button, Container, Typography } from '@mui/material';
import * as React from 'react';

type ErrorPageProps = {
  error: Error;
  resetErrorBoundary: () => void;

};

const ErrorPage: React.FC<ErrorPageProps> = ({ error, resetErrorBoundary }) => (
  <Container component="main" maxWidth="xl">
    <Typography component="h1" variant="h6">
      {error.name} {error.message}
    </Typography>
    <Typography component="h1" variant="body2">
      {error.stack}
    </Typography>
    <Button onClick={resetErrorBoundary}>
      Reset error
    </Button>
  </Container>
);

export default ErrorPage;
