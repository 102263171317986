// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Divider, Dialog, DialogTitle } from '@material-ui/core';
import { FormikProps } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import CountryList from 'react-select-country-list';
import { Rings as Loader } from 'react-loader-spinner';

import Section from './Section';
import { ArticleType } from '../../../../Articles/types/articles';
import { MyFormProps } from '../../../types/experts';
import { DropDown } from '../../../../../components';
import { ANNONS_TYPE, PRODUCT_OFFER_TYPE } from '../../../../../types/productOfferProperties';

const countryList = CountryList().getData();

export default function InnerForm(props: MyFormProps & FormikProps<any>) {
  const navigate = useNavigate();

  const {
    touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, values, setFieldValue, fetchExpertsCategoriesRequest, categories,
    articles, fetchArticlesRequest, fetchProductOffersRequest, dirty, articlesPage, deleteProductOfferRequest,
    fetchExpertArticlesRequest, createExpertArticlesRequest, deleteExpertArticleRequest, news, createdNews, fetchNewsRequest, newsPage,
    createdArticle, productOffersLoading, articlesLoading, isNews, isAnnons, experts, fetchExpertsRequest, expertsPage, expertLoading,
    requestCreateExpertsDynamicLink, expertError, isDynamicLinkLoading,
  } = props;
  const {
    name, addressCountry, addressLocality, streetAddress, email, website, phone, postalCode, expertCategories, logoUrl, longitude, latitude,
    expertArticles, id, offers, description, url, dynamicLink,
  } = values;

  // eslint-disable-next-line react/no-multi-comp
  const articleColumns = [
    {
      field: 'id', headerName: 'ID', width: 150, type: 'string',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 350,
      editable: false,
      type: 'string',
    },
    {
      field: 'ingress',
      headerName: 'Description',
      width: 600,
      editable: false,
      type: 'string',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 110,
      disableClickEventBubbling: true,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (params: any) => {
        const onClick = () => {
          navigate(`/experts/${id}/editExpertArticle/${params.row.id}`);
        };

        return <Button color="primary" onClick={onClick} variant="contained">EDIT</Button>;
      },
    },
    {
      field: 'delete',
      width: 120,
      headerName: 'Delete',
      disableClickEventBubbling: true,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (params: any) => {
        const onClick = () => {
          deleteExpertArticleRequest(id, params.row.id);
        };

        return <Button color="primary" onClick={onClick} variant="contained">DELETE</Button>;
      },
    },
  ];

  // eslint-disable-next-line react/no-multi-comp
  const newsColumns = [
    {
      field: 'id', headerName: 'ID', width: 150, type: 'string',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 350,
      editable: false,
      type: 'string',
    },
    {
      field: 'ingress',
      headerName: 'Description',
      width: 600,
      editable: false,
      type: 'string',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 110,
      disableClickEventBubbling: true,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (params: any) => {
        const onClick = () => {
          navigate(`/experts/${id}/editNews/${params.row.id}`);
        };

        return <Button color="primary" onClick={onClick} variant="contained">EDIT</Button>;
      },
    },
    {
      field: 'delete',
      width: 120,
      headerName: 'Delete',
      disableClickEventBubbling: true,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (params: any) => {
        const onClick = () => {
          deleteExpertArticleRequest(id, params.row.id);
        };

        return <Button color="primary" onClick={onClick} variant="contained">DELETE</Button>;
      },
    },
  ];

  const offerColumns = [
    {
      field: 'id', headerName: 'ID', width: 350, type: 'string', id: 'id',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 750,
      editable: false,
      type: 'string',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 110,
      disableClickEventBubbling: true,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (params: any) => {
        const onClick = () => {
          navigate(`/experts/${id}/editProductOffer/${params.row.id}`);
        };

        return <Button color="primary" onClick={onClick} variant="contained">EDIT</Button>;
      },
    },
    {
      field: 'delete',
      width: 120,
      headerName: 'Delete',
      disableClickEventBubbling: true,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (params: any) => {
        const onClick = () => {
          deleteProductOfferRequest(id, params.row.id);
        };

        return <Button color="primary" onClick={onClick} variant="contained">DELETE</Button>;
      },
    },
  ];

  const annonsColumns = [
    {
      field: 'id', headerName: 'ID', width: 350, type: 'string', id: 'id',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 750,
      editable: false,
      type: 'string',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 110,
      disableClickEventBubbling: true,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (params: any) => {
        const onClick = () => {
          navigate(`/experts/${id}/editAnnons/${params.row.id}`);
        };

        return <Button color="primary" onClick={onClick} variant="contained">EDIT</Button>;
      },
    },
    {
      field: 'delete',
      width: 120,
      headerName: 'Delete',
      disableClickEventBubbling: true,
      // eslint-disable-next-line react/no-multi-comp
      renderCell: (params: any) => {
        const onClick = () => {
          deleteProductOfferRequest(id, params.row.id);
        };

        return <Button color="primary" onClick={onClick} variant="contained">DELETE</Button>;
      },
    },
  ];

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    submit: {
      margin: theme.spacing(2, 0, 2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    formControlArticles: {
      width: '100%',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
    centeredRow: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    grid: { marginLeft: theme.spacing(2), marginBottom: theme.spacing(4), 'min-height': 380 },
  }));

  const classes = useStyles();

  const params = useParams();

  const loadExperts = useCallback(async (pageNumber: number) => fetchExpertsRequest(pageNumber), [experts]);
  const loadArticles = useCallback(async (pageNumber: number) => fetchArticlesRequest(pageNumber), [articles]);
  const loadNews = useCallback(async (pageNumber: number) => fetchNewsRequest(pageNumber), [news]);
  const loadExpertArticles = useCallback(async (id: string) => fetchExpertArticlesRequest(id), [expertArticles]);
  const addNewArticleToExpert = useCallback(async () => {
    if (
      createdArticle.expertId === id
      && expertArticles.findIndex((itm: any) => itm.id === createdArticle.id) < 0
      && createdArticle.type === ArticleType.LINK
    ) {
      await createExpertArticlesRequest(id, [createdArticle].map((itm: any) => (itm.id)), ArticleType.LINK);
    }
  }, [createdArticle.id]);

  // @ts-ignore
  const [selectedCategories, setSelectedCategories] = useState(categories.filter((item: any) => expertCategories?.indexOf(item.id) >= 0));
  const [selectedCountries, setSelectedCountries] = useState(addressCountry || null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (categories.length === 0) {
      fetchExpertsCategoriesRequest();
    }
  }, []);

  useEffect(() => {
    if (experts?.length === 0) {
      loadExperts(expertsPage);
    }
  }, []);

  useEffect(() => {
    if (
      createdNews.expertId === id
      && expertArticles.findIndex((itm: any) => itm.id === createdNews.id) < 0
      && createdNews.type === news
    ) {
      createExpertArticlesRequest(id, [createdNews].map((itm: any) => (itm.id)), news);
    }
  }, [createdNews.id]);

  useEffect(() => {
    addNewArticleToExpert();
  }, [createdArticle.id]);

  useEffect(() => {
    if (expertError) {
      setShowError(true);
    }
  }, [expertError]);

  return (
    <Container component="main" maxWidth="xl">
      <div className={classes.paper}>
        <Typography component="h3" variant="h4">
          {Object.hasOwnProperty.call(params, 'id') ? 'Edit expert' : 'Create expert'}
        </Typography>

        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            if (Object.keys(errors).length === 0) {
              handleSubmit();
              navigate('/experts');
            }
          }}
        >
          <Typography component="h3" variant="h6">
            Expert main data
          </Typography>

          {expertLoading ? (
            <Loader color="gray" height={80} type="ThreeDots" width={80} />
          ) : (
            <Container className={classes.row} maxWidth="xl">
              <Container maxWidth="xs">
                <TextField
                  error={(touched.name ? Object.hasOwnProperty.call(errors, 'name') : null)}
                  fullWidth
                  helperText={(touched.name && errors.name) ? errors.name : null}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={name}
                  variant="standard"
                />

                <TextField
                  error={(touched.description ? Object.hasOwnProperty.call(errors, 'description') : null)}
                  fullWidth
                  helperText={(touched.description && errors.description) ? errors.description : null}
                  label="Description"
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={description}
                  variant="standard"
                />

                <TextField
                  error={(touched.url ? Object.hasOwnProperty.call(errors, 'url') : null)}
                  fullWidth
                  helperText={(touched.url && errors.url) ? errors.url : null}
                  label="Description url"
                  margin="normal"
                  name="url"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={url}
                  variant="standard"
                />

                <TextField
                  error={(touched.logoUrl ? Object.hasOwnProperty.call(errors, 'logoUrl') : null)}
                  fullWidth
                  helperText={(touched.logoUrl && errors.logoUrl) ? errors.logoUrl : null}
                  label="logoUrl"
                  margin="normal"
                  name="logoUrl"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={logoUrl}
                  variant="standard"
                />

                <TextField
                  error={(touched.addressLocality ? Object.hasOwnProperty.call(errors, 'addressLocality') : null)}
                  fullWidth
                  helperText={(touched.addressLocality && errors.addressLocality) ? errors.addressLocality : null}
                  label="City"
                  margin="normal"
                  name="addressLocality"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={addressLocality}
                  variant="standard"
                />

                <TextField
                  error={(touched.streetAddress ? Object.hasOwnProperty.call(errors, 'streetAddress') : null)}
                  fullWidth
                  helperText={(touched.streetAddress && errors.streetAddress) ? errors.streetAddress : null}
                  label="Street"
                  margin="normal"
                  name="streetAddress"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={streetAddress}
                  variant="standard"
                />

                {isDynamicLinkLoading
                  ? (<Loader color="gray" height={80} type="ThreeDots" width={80} />)
                  : (
                    <TextField
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="DynamicLink"
                      margin="normal"
                      name="DynamicLink"
                      value={dynamicLink?.shortLink}
                      variant="standard"
                    />
                  )}

                <DropDown
                  error={Object.hasOwnProperty.call(errors, 'addressCountry') && errors.addressCountry}
                  itemId="value"
                  itemText="label"
                  itemValue="value"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const value = event.target.value as string;
                    setFieldValue('addressCountry', value);
                    setSelectedCountries(value);
                  }}
                  options={countryList}
                  selectedOptions={selectedCountries}
                  title="Countries"
                  value={selectedCountries}
                />
              </Container>

              <Container maxWidth="xs">
                <TextField
                  error={(touched.email ? Object.hasOwnProperty.call(errors, 'email') : null)}
                  fullWidth
                  helperText={(touched.email && errors.email) ? errors.email : null}
                  label="email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={email}
                  variant="standard"
                />

                <TextField
                  error={(touched.website ? Object.hasOwnProperty.call(errors, 'website') : null)}
                  fullWidth
                  helperText={(touched.website && errors.website) ? errors.website : null}
                  label="Website"
                  margin="normal"
                  name="website"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={website}
                  variant="standard"
                />

                <TextField
                  error={(touched.phone ? Object.hasOwnProperty.call(errors, 'phone') : null)}
                  fullWidth
                  helperText={(touched.phone && errors.phone) ? errors.phone : null}
                  label="Phone"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={phone}
                  variant="standard"
                />

                <TextField
                  error={(touched.postalCode ? Object.hasOwnProperty.call(errors, 'postalCode') : null)}
                  fullWidth
                  helperText={(touched.postalCode && errors.postalCode) ? errors.postalCode : null}
                  label="Postal code"
                  margin="normal"
                  name="postalCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={postalCode}
                  variant="standard"
                />

                <TextField
                  error={(touched.longitude ? Object.hasOwnProperty.call(errors, 'longitude') : null)}
                  fullWidth
                  helperText={(touched.longitude && errors.longitude) ? errors.longitude : null}
                  label="longitude"
                  margin="normal"
                  name="longitude"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={longitude}
                  variant="standard"
                />

                <TextField
                  error={(touched.latitude ? Object.hasOwnProperty.call(errors, 'latitude') : null)}
                  fullWidth
                  helperText={(touched.latitude && errors.latitude) ? errors.latitude : null}
                  label="latitude"
                  margin="normal"
                  name="latitude"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={latitude}
                  variant="standard"
                />

                {id ? (
                  <Button
                    className={classes.submit}
                    color="primary"
                    disabled={!id}
                    fullWidth
                    onClick={async () => {
                      await requestCreateExpertsDynamicLink(id);
                    }}
                    variant="contained"
                  >
                    Generate LINK
                  </Button>
                ) : null}

                <DropDown
                  errors={Object.hasOwnProperty.call(errors, 'expertCategories') && errors.expertCategories}
                  isMultiSelect
                  itemId="id"
                  itemText="name"
                  itemValue="id"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const values = event.target.value as Array<string>;
                    const selectedCategory = categories.filter((category: any) => values.indexOf(category.id) >= 0);
                    setSelectedCategories(selectedCategory);
                    setFieldValue('expertCategories', selectedCategory?.map((artCategory: any) => artCategory.id));
                  }}
                  options={categories}
                  selectedOptions={selectedCategories}
                  title="Categories"
                  value={selectedCategories?.map((category: any) => category.id || category)}
                />
              </Container>
            </Container>
          )}

          <Container className={classes.column} maxWidth="xl">
            <Container className={classes.centeredRow} maxWidth="xl">
              <Container maxWidth="xs">
                <Button
                  className={classes.submit}
                  color="primary"
                  disabled={isSubmitting || Object.keys(errors).length > 0 || !dirty}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  {Object.hasOwnProperty.call(params, 'id') ? 'Save' : 'Create'}
                </Button>
              </Container>
            </Container>

            <Divider />
          </Container>

          <Container className={classes.column} maxWidth="xl">
            <Container className={classes.centeredRow} maxWidth="xl">
              <Typography component="h3" variant="h6">
                Connected entities
              </Typography>
            </Container>

            <Section
              buttonTitle="Create Article"
              columns={articleColumns}
              createEntityRequestFunc={(id: any, ids: any, type: any) => createExpertArticlesRequest(id, ids, type)}
              entity={articles}
              expertEntity={expertArticles}
              id={id}
              loadEntity={() => loadArticles(articlesPage)}
              loadExpertEntity={loadExpertArticles}
              loading={articlesLoading ? !isNews : null}
              onClick={() => navigate(`/experts/${id}/createExpertArticle`)}
              rowsFunc={(selectedEntity: any) => selectedEntity.map((itm: any) => ({
                id: itm.id,
                title: itm.title,
                ingress: itm.ingress,
              }))}
              title="Articles"
              type={ArticleType.LINK}
            />

            <Section
              buttonTitle="Create news"
              columns={newsColumns}
              entity={news}
              expertEntity={expertArticles}
              id={id}
              loadEntity={() => loadNews(newsPage)}
              loadExpertEntity={loadExpertArticles}
              loading={articlesLoading ? isNews : null}
              onClick={() => navigate(`/experts/${id}/createNews`)}
              rowsFunc={(selectedEntity: any) => selectedEntity.map((itm: any) => ({
                id: itm.id,
                title: itm.title,
                ingress: itm.ingress,
              }))}
              title="news"
              type={news}
            />

            <Section
              buttonTitle="Add Product Offer"
              columns={offerColumns}
              entity={offers}
              expertEntity={offers}
              id={id}
              loadExpertEntity={fetchProductOffersRequest}
              loading={productOffersLoading ? !isAnnons : null}
              onClick={() => navigate(`/experts/${id}/createProductOffer`)}
              rowsFunc={(selectedEntity: any) => selectedEntity.filter((offer: any) => offer.type === PRODUCT_OFFER_TYPE).map((offer: any) => ({
                id: offer.id,
                title: offer.name,
              }))}
              title="Product Offer"
              type={PRODUCT_OFFER_TYPE}
            />

            <Section
              buttonTitle="Add Annons"
              columns={annonsColumns}
              entity={offers}
              expertEntity={offers}
              id={id}
              loadExpertEntity={fetchProductOffersRequest}
              loading={productOffersLoading ? isAnnons : null}
              onClick={() => navigate(`/experts/${id}/createAnnons`)}
              rowsFunc={(selectedEntity: any) => selectedEntity.filter((offer: any) => offer.type === ANNONS_TYPE).map((offer: any) => ({
                id: offer.id,
                title: offer.name,
              }))}
              title="Annons"
              type={ANNONS_TYPE}
            />
          </Container>
        </form>

        <Dialog aria-labelledby="simple-dialog-title" onClose={() => setShowError(false)} open={showError}>
          <DialogTitle id="simple-dialog-title">{expertError}</DialogTitle>
        </Dialog>
      </div>
    </Container>
  );
}
