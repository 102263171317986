// @ts-nocheck
import React, { useState } from 'react';
import {
  Dialog, DialogContent, DialogTitle, Button, Typography, IconButton, makeStyles,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

interface SearchDialogProps {
  data: any;
  searchTitle: string;
  pickedItemsSetter: any;
  columns: any;
  selectionModel: any;
  buttonTitle: string;
  supportMultiSelection?: boolean | undefined;
  disabled?: boolean | undefined;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function SearchDialog({
  data,
  searchTitle,
  pickedItemsSetter,
  columns,
  selectionModel,
  buttonTitle,
  supportMultiSelection = true,
  disabled,
}: SearchDialogProps) {
  const classes = useStyles();

  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    pickedItemsSetter(selectedItems);
    setOpen(false);
  };
  const handleClickOpen = () => setOpen(true);
  return (
    <div>
      <Button
        color="primary"
        disabled={disabled}
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
        variant="contained"
      >
        {buttonTitle}
      </Button>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        disableEnforceFocus
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6">{searchTitle}</Typography>

          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ height: 400 }}>
          <DataGrid
            checkboxSelection={supportMultiSelection}
            columns={columns}
            disableMultipleSelection={supportMultiSelection ? undefined : true}
            disableSelectionOnClick={supportMultiSelection ? true : undefined}
            onCellDoubleClick={() => !supportMultiSelection && setTimeout(handleClose, 0)}
            onSelectionModelChange={(ids: any) => setSelectedItems(ids)}
            pageSize={50}
            rows={data}
            selectionModel={selectionModel}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SearchDialog;
