import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QueryHook } from '../../../types/common';
import { UCAddress } from '../../../types/verifyPropertyProperties';
import { searchUCAddressRequest } from '../reducers/verifyProperty';

type UseGetUCAddresses = (query?: string) => QueryHook<Array<UCAddress>>;

const useGetUCAddresses: UseGetUCAddresses = (query) => {
  const dispatch = useDispatch();

  const addresses = useSelector(({ verifyProperty }) => verifyProperty.addresses);
  const addressesError = useSelector(({ verifyProperty }) => verifyProperty.addressesError);
  const addressesLoading = useSelector(({ verifyProperty }) => verifyProperty.addressesLoading);
  const previousQuery = useSelector(({ verifyProperty }) => verifyProperty.query);

  const fetch = () => dispatch(searchUCAddressRequest(query));

  useEffect(() => {
    if (!addressesLoading && query && query.length > 3 && (query !== previousQuery)) {
      fetch();
    }
  }, [query]);

  return {
    data: addresses,
    loading: addressesLoading,
    error: addressesError,
    fetch,
    refetch: fetch,
  };
};

export default useGetUCAddresses;
