import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Form from './LoginForm';

export default function LoginForm() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      flex: 1,
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      flexDirection: 'column',
      alignItems: 'center',
    },
  }));

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container component="main" maxWidth="sm" className={classes.paper}>
      <Paper variant="elevation" sx={{
        padding: theme.spacing(2),
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <LockOpenIcon fontSize="large" />
        <Typography component="h1" variant="h5"> Sign in </Typography>

        <Form />
      </Paper>
    </Container>
  );
}
