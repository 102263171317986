import {
  Button, Avatar,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from 'react-router-dom';
import { DeleteDialog } from '../../../../../components';
import { deleteExpertRequest } from '../../../reducers/experts';

const useStyles = makeStyles((theme: Theme) => createStyles({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const useExpertColumns = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const renderCountry = useCallback((params) => <ReactCountryFlag
    countryCode={params.row.place.address.addressCountry}
    svg
    style={{
      fontSize: 36,
      flex: 0.5,
    }}
  />, []);

  const renderAvatar = useCallback((params) => <Avatar variant="rounded" className={classes.large} src={params?.row.logoUrl} />, []);

  const handleArticleClick = (params) => {
    navigate(`/experts/edit/${params.row.id}`);
  };

  return [
    {
      field: 'addressCountry',
      headerName: 'Country',
      flex: 0.2,
      renderCell: (params) => renderCountry(params),
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 1.5,
      minWidth: 300,
    },
    {
      field: 'logoUrl',
      headerName: 'Logo',
      flex: 0.5,
      renderCell: (params) => renderAvatar(params),
    },
    {
      field: 'place',
      headerName: 'Title',
      flex: 1,
      valueGetter: (params) => params?.row.place.name,
    },
    {
      field: 'addressLocality',
      headerName: 'Address Locality',
      flex: 3,
      valueGetter: ({ row }) =>
        [row.place.address.postalCode, row.place.address.addressLocality, row.place.address.streetAddress].join(', '),
    },
    {
      headerName: 'Phone',
      field: 'phone',
      flex: 1,
      valueGetter: (params) => params?.row.place.contactDetails.phone,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      flex: 0.5,
      renderCell: (params) =>
        <Button color="primary" onClick={() => handleArticleClick(params)} variant="contained">EDIT</Button>,

    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      flex: 0.7,
      renderCell: (params) =>
        <DeleteDialog
          deleteMessage="Are you sure you want to delete expert?"
          deleteRequest={deleteExpertRequest}
          deleteTitle="Delete expert"
          id={params.id}
        />,
    },
  ];
};

export default useExpertColumns;
