import * as React from 'react';

import {
  Stack, Snackbar,
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import useGetAlert from '../../hooks/useGetAlert';
import { hideAlert } from '../../store/reducers/alerts';
import { AlertsAutoHideDuration } from '../../constants/common';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
  props,
  ref,
) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const CustomSnackbar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const alert = useGetAlert();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideAlert(alert));
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={(Boolean(alert))} autoHideDuration={AlertsAutoHideDuration} onClose={handleClose} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
        {alert && (
          <Alert onClose={handleClose} severity={alert.alertType} sx={{ width: '100%' }} style={{ marginTop: theme.spacing(6) }}>
            {alert.message}
          </Alert>)
        }
      </Snackbar>
    </Stack>
  );
};

export default CustomSnackbar;
