// @ts-nocheck

import { makeStyles } from '@material-ui/core/styles';
import React, { useRef } from 'react';
import {
  Container, TextField, Typography, Box, ButtonBase,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import useOnStateChange from '../../../../hooks/useOnStateChange';
import { requestSendMessage } from '../../reducers/messages';
import { RootState } from '../../../../store/reducers';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
}));

const schema = Yup.object().shape({
  propertyId: Yup.string().required('property required').min(10, 'PropertyId is too short, 10 chars minimum'),
  subject: Yup.string().required('subject required').min(4, 'Subject is too short - should be 4 chars minimum.'),
  body: Yup.string(),
  files: Yup.array(),
});

const UploadDocumentToPropertyForm = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();

  const messageError = useSelector(({ messages }: RootState) => messages.error);
  const messageLoading = useSelector(({ messages }: RootState) => messages.loading);

  const resetFormIfSuccess = () => {
    if (messageError) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resetFormFull();
  };

  const proceedAfterSubmit = useOnStateChange(
    ({ messages }: RootState) => messages.loading,
    resetFormIfSuccess,
  );

  const {
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: false,
    initialValues: {
      propertyId: '',
      subject: '',
      body: '',
      files: [],
    },
    onSubmit: (data: any) => {
      const {
        propertyId, files, subject, body,
      } = data;
      const message = {
        target: { propertyId },
        sender: { type: 'system' },
        subject,
        htmlBody: body,
        data: {},
      };
      dispatch(requestSendMessage(propertyId, message, files));
      proceedAfterSubmit();
    },
  });

  const resetFormFull = () => {
    resetForm();
    setFieldValue('files', []);
  };

  const {
    body, subject, propertyId, files,
  } = values;

  const fileNames = files.map((f) => f.name).join(', ');

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Upload document to property
        </Typography>

        <form
          className={classes.formControl}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <TextField
            autoFocus
            error={(errors?.propertyId && touched?.propertyId) || false}
            fullWidth
            helperText={(errors.propertyId && touched.propertyId) ? errors.propertyId : null}
            label="Property"
            margin="normal"
            name="propertyId"
            onBlur={handleBlur}
            onChange={handleChange}
            value={propertyId}
            variant="standard"
          />

          <TextField
            error={(errors?.subject && touched?.subject) || false}
            fullWidth
            helperText={(errors.subject && touched.subject) ? errors.subject : ''}
            label="Subject"
            margin="normal"
            name="subject"
            onBlur={handleBlur}
            onChange={handleChange}
            value={subject}
            variant="standard"
          />

          <TextField
            fullWidth
            label="Body"
            margin="normal"
            multiline
            name="body"
            onBlur={handleBlur}
            onChange={handleChange}
            rows={5}
            value={body}
            variant="standard"
          />

          <Box
            height={98}
            position="relative"
          >
            <Box bottom={0} left={0} mx={0} position="absolute" right={0} top={0}>
              <TextField
                error={(errors.files && touched.files) || false}
                fullWidth
                helperText={(errors.files && touched.files) ? errors.files : null}
                label="Files"
                margin="normal"
                name="files"
                value={fileNames}
                variant="standard"
              />
            </Box>

            <ButtonBase
              component="label"
              onClick={() => setFieldTouched('files', true)}
              onKeyDown={(e: any) => {
                // @ts-ignore
                if (e.keyCode === 32) {
                  ref.current?.click();
                }
              }}
              style={{ width: '100%', height: '100%', overflow: 'hidden' }}
            >
              <input
                ref={ref}
                accept="image/*, application/pdf"
                hidden
                id="fileInputHidden"
                multiple
                onChange={(e: any) => {
                  files.push(...Object.values(e.target.files));
                  setFieldValue('files', files);
                }}
                type="file"
              />
            </ButtonBase>
          </Box>

          <div>
            <LoadingButton
              loading={messageLoading}
              className={classes.submit}
              color="primary"
              disabled={messageLoading || isSubmitting || Object.keys(errors).length > 0}
              fullWidth
              type="submit"
              variant="contained"
            >
              Upload
            </LoadingButton>

            <LoadingButton
              color="inherit"
              onClick={resetFormFull}
              fullWidth
              sx={{ marginTop: theme.spacing(2) }}
              variant="contained"
            >
              Reset form
            </LoadingButton>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default UploadDocumentToPropertyForm;
