/* eslint-disable no-param-reassign */
import { Article, DynamicLink } from '../types/entities';

export const updateExpertArticlesItem = (expertArticles: Array<Article>, dataObj: any) => {
  const { expertId, data, articleType } = dataObj;
  const articlesArray = data.flatMap((itm: any) => itm).map(((itm: any) => itm.article_id));
  // @ts-ignore
  articlesArray.forEach((id: string) => expertArticles.find((itm: any) => itm[expertId])[expertId].push({ id, type: articleType }));
  return expertArticles;
};

export const deleteExpertArticleItem = (expertArticles: Array<Article>, idsObject: any) => {
  const newArticlesArray = expertArticles.flatMap((itm: any) =>
    itm[idsObject.expertId]).filter((itm: any) => itm && itm.id !== idsObject.articleId).filter((itm: any) => itm);
  return expertArticles.map((itm: any) => (itm[idsObject.expertId] ? { [idsObject.expertId]: newArticlesArray } : itm));
};

export const addExpertArticleItem = (expertArticles: any, data: any) => {
  const property = Object.hasOwnProperty.call(data, 'expert_id') ? 'expert_id' : 'expertId';
  const index = expertArticles.findIndex((itm: any) => itm[data[property]]);
  if (index < 0) {
    expertArticles.push(data);
  } else {
    expertArticles[index] = data;
  }
  return expertArticles;
};

export const addToDynamicLinks = (dynamicLinks: Array<any>, id: string, link: DynamicLink) => {
  dynamicLinks.push({ id, link });
  return dynamicLinks;
};
