import { combineReducers } from 'redux';
import { articlesReducer, ArticlesState } from '../../modules/Articles/reducers/articles';
import { authReducer, AuthState } from '../../modules/User/reducers/auth';
import { expertsReducer, ExpertsState } from '../../modules/Experts/reducers/experts';
import { productOffersReducer, ProductOfferState } from '../../modules/Experts/reducers/productOffer';
import { blobsReducer } from './blob';
import { messagesReducer } from '../../modules/Actions/reducers/messages';
import { verifyPropertyReducer, VerifyPropertyState } from '../../modules/Actions/reducers/verifyProperty';
import { alertsReducer, AlertsState } from './alerts';
import { propertyCheckReducer, PropertyCheckState } from '../../modules/PropertyChecks/reducers/propertyCheck';

export type RootState = {
  auth: AuthState,
  articles: ArticlesState,
  experts: ExpertsState,
  blobs: any,
  messages: any,
  productOffers: ProductOfferState,
  verifyProperty: VerifyPropertyState,
  alerts: AlertsState,
  propertyCheck: PropertyCheckState,
};

export default combineReducers({
  auth: authReducer,
  articles: articlesReducer,
  experts: expertsReducer,
  blobs: blobsReducer,
  messages: messagesReducer,
  productOffers: productOffersReducer,
  verifyProperty: verifyPropertyReducer,
  alerts: alertsReducer,
  propertyCheck: propertyCheckReducer,
});
