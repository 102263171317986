import _ from 'lodash';

import { getAPIPath, postFormData } from './core';

export const createBlobRequest = (data: any) => {
  const { propertyId, file } = data;
  if (!file) {
    return Promise.resolve({});
  }
  const formData = new FormData();
  Array.from(file).forEach((item: any) => {
    formData.append(item.name, item);
  });

  return postFormData(getAPIPath(`properties/${propertyId}/blobs`), formData);
};

export const uploadBlobsRequest = (propertyId: string, files: Array<any>) => {
  if (_.isEmpty(files)) {
    return Promise.resolve([]);
  }
  const formData = new FormData();
  files.forEach((item: any) => {
    formData.append(item.name, item);
  });

  return postFormData(getAPIPath(`properties/${propertyId}/blobs`), formData)
    .then(({ data }) => data.blobs.map((b) => b.id));
};
