export const getEnumKeys = <T>(enumeration: T) => (Object.keys(enumeration) as Array<keyof T>)
  .filter((key) => Number.isNaN(Number(key)))
  .filter((key) => typeof enumeration[key] === 'number' || typeof enumeration[key] === 'string');

export const getEnumEntries = <T>(enumeration: T) => getEnumKeys(enumeration)
  .map((key) => ([key, enumeration[key]])) as Array<[keyof T, T[keyof T]]>;

export const enumToMap = <T>(enumeration: T) => Object.fromEntries(getEnumEntries(enumeration));

export const getEnumValues = <T>(enumeration: T) => getEnumKeys(enumeration)
  .map((key) => (enumeration[key]));
