// @ts-nocheck
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FC } from 'react';
import { FieldInfo } from '../types/formBuilder';

const FormSelector: FC<FieldInfo> = ({
  id,
  label,
  required,
  value,
  onChange,
  onBlur,
  error,
  touched,
  options,
}) => {
  const labelId = `${id}-label`;
  const showError = Boolean(touched && error);

  return (
    <FormControl variant="standard" fullWidth helperText={showError ? error : ''}>
      <InputLabel
        id={labelId}
        required={required}
        error={showError}
        helperText={showError ? error : ''}
      >
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        id={id}
        onBlur={onBlur}
        value={value}
        fullWidth
        sx={{ textAlign: 'left' }}
        onChange={(event) => onChange(event.target.value)}
        label={label}
      >
        {options.map(({ key, value }) => (<MenuItem key={key} value={key}>{value}</MenuItem>))}
      </Select>
    </FormControl>
  );
};

export default FormSelector;
