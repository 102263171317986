import { TextField } from '@mui/material';
import React, { FC } from 'react';
import { FieldInfo } from '../types/formBuilder';

const FormTextInput: FC<FieldInfo> = ({
  id,
  label,
  required,
  value,
  onChange,
  onBlur,
  error,
  touched,
  autoFocus,
}) => {
  const showError = Boolean(touched && error);

  return (
    <TextField
      autoFocus={autoFocus}
      id={id}
      required={required}
      error={showError}
      helperText={showError ? error : ''}
      label={label}
      defaultValue={value}
      onBlur={onBlur}
      onChange={(event) => onChange(event.target.value)}
      placeholder="Please specify the value"
      variant="standard"
      fullWidth
    />
  );
};

export default FormTextInput;
