import { request, getAdminAPIPath, getAPIPath } from '../../../api/core';

export const searchUCAddress = (searchQuery: string) => request(getAPIPath(`/uc/search?q=${searchQuery}`), {
  method: 'GET',
});

export const verifyProperty = (propertyId: string, data: any) => request(getAdminAPIPath(`/uc/verify/${propertyId}`), {
  method: 'POST',
  data,
});
