import {
  all, put, takeLatest, call,
} from 'redux-saga/effects';
import {
  fetchExperts,
  fetchExpertsCategories,
  createExpert,
  updateExpert,
  deleteExpert,
  fetchExpertArticles,
  createExpertArticles,
  deleteExpertArticle,
  searchExperts,
  getDynamicLinkExperts,
} from '../api/experts';
import {
  Expert, Categories, Article, DynamicLink,
} from '../../../types/entities';
import {
  updateExpertArticlesItem, deleteExpertArticleItem, addExpertArticleItem, addToDynamicLinks,
} from '../../../services/experts';
import { ActionError, ResponseData } from '../../../types/common';
import { showActionErrorAlert, showSuccessAlert } from '../../../store/reducers/alerts';

const FETCH_EXPERTS_REQUEST = 'FETCH_EXPERTS_REQUEST';
const FETCH_EXPERTS_SUCCESS = 'FETCH_EXPERTS_SUCCESS';
const FETCH_EXPERTS_FAILED = 'FETCH_EXPERTS_FAILED';

const FETCH_EXPERTS_CATEGORIES_REQUEST = 'FETCH_EXPERTS_CATEGORIES_REQUEST';
const FETCH_EXPERTS_CATEGORIES_SUCCESS = 'FETCH_EXPERTS_CATEGORIES_SUCCESS';
const FETCH_EXPERTS_CATEGORIES_FAILED = 'FETCH_EXPERTS_CATEGORIES_FAILED';

const CREATE_EXPERT_REQUEST = 'CREATE_EXPERT_REQUEST';
const CREATE_EXPERT_SUCCESS = 'CREATE_EXPERT_SUCCESS';
const CREATE_EXPERT_FAILED = 'CREATE_EXPERT_FAILED';

const UPDATE_EXPERT_REQUEST = 'UPDATE_EXPERT_REQUEST';
const UPDATE_EXPERT_SUCCESS = 'UPDATE_EXPERT_SUCCESS';
const UPDATE_EXPERT_FAILED = 'UPDATE_EXPERT_FAILED';

const DELETE_EXPERT_REQUEST = 'DELETE_EXPERT_REQUEST';
const DELETE_EXPERT_SUCCESS = 'DELETE_EXPERT_SUCCESS';
const DELETE_EXPERT_FAILED = 'DELETE_EXPERT_FAILED';

const FETCH_EXPERT_ARTICLES_REQUEST = 'FETCH_EXPERT_ARTICLES_REQUEST';
const FETCH_EXPERT_ARTICLES_SUCCESS = 'FETCH_EXPERT_ARTICLES_SUCCESS';
const FETCH_EXPERT_ARTICLES_FAILED = 'FETCH_EXPERT_ARTICLES_FAILED';

const CREATE_EXPERT_ARTICLES_REQUEST = 'CREATE_EXPERT_ARTICLES_REQUEST';
const CREATE_EXPERT_ARTICLES_SUCCESS = 'CREATE_EXPERT_ARTICLES_SUCCESS';
const CREATE_EXPERT_ARTICLES_FAILED = 'CREATE_EXPERT_ARTICLES_FAILED';

const DELETE_EXPERT_ARTICLE_REQUEST = 'DELETE_EXPERT_ARTICLE_REQUEST';
const DELETE_EXPERT_ARTICLE_SUCCESS = 'DELETE_EXPERT_ARTICLE_SUCCESS';
const DELETE_EXPERT_ARTICLE_FAILED = 'DELETE_EXPERT_ARTICLE_FAILED';

const SEARCH_EXPERTS_REQUEST = 'SEARCH_EXPERTS_REQUEST';
const SEARCH_EXPERTS_SUCCESS = 'SEARCH_EXPERTS_SUCCESS';
const SEARCH_EXPERTS_FAILED = 'SEARCH_EXPERTS_FAILED';

const CREATE_EXPERTS_DYNAMIC_LINK_REQUEST = 'CREATE_EXPERTS_DYNAMIC_LINK_REQUEST';
const CREATE_EXPERTS_DYNAMIC_LINK_SUCCESS = 'CREATE_EXPERTS_DYNAMIC_LINK_SUCCESS';
const CREATE_EXPERTS_DYNAMIC_LINK_FAILED = 'CREATE_EXPERTS_DYNAMIC_LINK_FAILED';

const DYNAMIC_LINK_COPIED = 'DYNAMIC_LINK_COPIED';

export interface ExpertsState {
  experts: Array<Expert>,
  expertsLoading: boolean,
  expertsError: ActionError,

  error: string,
  categories: Array<Categories>,
  articles: Array<Article>,
  dynamicLinks: Array<any>,
  isGenerateLinkRequestSuccess: boolean,
  isGeneratedLinkCopied: boolean,
  isDynamicLinkLoading: boolean,
}

export interface ExpertsStateAction {
  type: string;
  data: any,
  error: ActionError | null,
  experts: Array<Expert>,
  pageNumber: number,
  pageSize: number,

  expert: Expert,
  categories: Array<Categories>,

  expertId: string,
  articleId: string,
  articleType: string,
  dynamicLink: DynamicLink,
}

const initState = {
  experts: [],
  expertsLoading: false,
  expertsError: null,

  categories: [],
  loading: false,
  error: '',
  articles: [],
  dynamicLinks: [],
  isGenerateLinkRequestSuccess: false,
  isGeneratedLinkCopied: false,
  isDynamicLinkLoading: false,
};

export const expertsReducer = (
  state: ExpertsState = initState,
  action: ExpertsStateAction,
) => {
  switch (action.type) {
    case FETCH_EXPERTS_REQUEST:
      return {
        ...state,
        expertsLoading: true,
        expertsError: '',
      };
    case FETCH_EXPERTS_SUCCESS: {
      return {
        ...state,
        expertsLoading: false,
        experts: [...state.experts, ...action.experts],
      };
    }
    case FETCH_EXPERTS_FAILED: {
      return {
        ...state,
        expertsLoading: false,
        expertsError: action.error,
      };
    }
    case FETCH_EXPERTS_CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case FETCH_EXPERTS_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        categories: action.categories,
      };
    }
    case FETCH_EXPERTS_CATEGORIES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CREATE_EXPERT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case CREATE_EXPERT_SUCCESS: {
      const tmpExperts = [...state.experts];
      tmpExperts.unshift(action.expert);
      return {
        ...state,
        loading: false,
        isLoaded: true,
        experts: tmpExperts,
      };
    }
    case CREATE_EXPERT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case UPDATE_EXPERT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case UPDATE_EXPERT_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        experts: state.experts.map((expert: any) => (expert.id === action.expert.id ? action.expert : expert)),
      };
    }
    case UPDATE_EXPERT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_EXPERT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case DELETE_EXPERT_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        experts: state.experts.filter((expert) => expert.id !== action.expertId),
      };
    }
    case DELETE_EXPERT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case FETCH_EXPERT_ARTICLES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case FETCH_EXPERT_ARTICLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        articles: addExpertArticleItem(state.articles, action.data),
      };
    }
    case FETCH_EXPERT_ARTICLES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CREATE_EXPERT_ARTICLES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case CREATE_EXPERT_ARTICLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        articles: updateExpertArticlesItem(state.articles, action.data),
      };
    }
    case CREATE_EXPERT_ARTICLES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_EXPERT_ARTICLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case DELETE_EXPERT_ARTICLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        articles: deleteExpertArticleItem(state.articles, action.data),
      };
    }
    case DELETE_EXPERT_ARTICLE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case SEARCH_EXPERTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SEARCH_EXPERTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        experts: action.data,
      };
    }
    case SEARCH_EXPERTS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CREATE_EXPERTS_DYNAMIC_LINK_REQUEST:
      return {
        ...state,
        isGenerateLinkRequestSuccess: false,
        isGeneratedLinkCopied: false,
        isDynamicLinkLoading: true,
        error: '',
      };
    case CREATE_EXPERTS_DYNAMIC_LINK_SUCCESS:
      return {
        ...state,
        isGenerateLinkRequestSuccess: true,
        isDynamicLinkLoading: false,
        dynamicLinks: addToDynamicLinks(state.dynamicLinks, action.expertId, action.dynamicLink),
      };
    case CREATE_EXPERTS_DYNAMIC_LINK_FAILED:
      return {
        ...state,
        isGenerateLinkRequestSuccess: false,
        isGeneratedLinkCopied: false,
        isDynamicLinkLoading: false,
        error: action.error,
      };
    case DYNAMIC_LINK_COPIED:
      return {
        ...state,
        isGeneratedLinkCopied: true,
      };
    default:
      return state;
  }
};

export const fetchExpertsRequest = (pageNumber: number, pageSize: number): ExpertsStateAction =>
  ({ type: FETCH_EXPERTS_REQUEST, pageNumber, pageSize } as ExpertsStateAction);

export const fetchExpertsSuccess = (
  experts: Array<Expert> | null,
): ExpertsStateAction => ({ type: FETCH_EXPERTS_SUCCESS, experts } as ExpertsStateAction);

export const fetchExpertsFailure = (error: ActionError): ExpertsStateAction => ({ type: FETCH_EXPERTS_FAILED, error } as ExpertsStateAction);

export const fetchExpertsCategoriesRequest = (): ExpertsStateAction => ({ type: FETCH_EXPERTS_CATEGORIES_REQUEST } as ExpertsStateAction);

export const fetchExpertsCategoriesSuccess = (
  categories: Array<Categories> | null,
): ExpertsStateAction => ({ type: FETCH_EXPERTS_CATEGORIES_SUCCESS, categories } as ExpertsStateAction);

export const fetchExpertsCategoriesFailure = (error: ActionError): ExpertsStateAction =>
  ({ type: FETCH_EXPERTS_CATEGORIES_FAILED, error } as ExpertsStateAction);

export const createExpertRequest = (data: any): ExpertsStateAction => ({ type: CREATE_EXPERT_REQUEST, data } as ExpertsStateAction);

export const createExpertSuccess = (expert: Expert): ExpertsStateAction => ({ type: CREATE_EXPERT_SUCCESS, expert } as ExpertsStateAction);

export const createExpertFailed = (error: ActionError): ExpertsStateAction => ({ type: CREATE_EXPERT_FAILED, error } as ExpertsStateAction);

export const updateExpertRequest = (expertId: string, data: any): ExpertsStateAction => ({ type: UPDATE_EXPERT_REQUEST, expertId, data } as ExpertsStateAction);

export const updateExpertSuccess = (expert: Expert): ExpertsStateAction => ({ type: UPDATE_EXPERT_SUCCESS, expert } as ExpertsStateAction);

export const updateExpertFailed = (error: ActionError): ExpertsStateAction => ({ type: UPDATE_EXPERT_FAILED, error } as ExpertsStateAction);

export const deleteExpertRequest = (expertId: string): ExpertsStateAction => ({ type: DELETE_EXPERT_REQUEST, expertId } as ExpertsStateAction);

export const deleteExpertSuccess = (
  expertId: string,
): ExpertsStateAction => ({ type: DELETE_EXPERT_SUCCESS, expertId } as ExpertsStateAction);

export const deleteExpertFailure = (error: ActionError): ExpertsStateAction => ({ type: DELETE_EXPERT_FAILED, error } as ExpertsStateAction);

export const fetchExpertArticlesRequest = (expertId: any) => ({
  type: FETCH_EXPERT_ARTICLES_REQUEST,
  expertId,
});

export const copyGeneratedLinkRequest = () => ({
  type: DYNAMIC_LINK_COPIED,
});

export const createExpertArticlesRequest = (expertId: string, data: any, articleType: string) => ({
  type: CREATE_EXPERT_ARTICLES_REQUEST,
  expertId,
  data,
  articleType,
});

export const deleteExpertArticleRequest = (expertId: string, articleId: string) => ({
  type: DELETE_EXPERT_ARTICLE_REQUEST,
  expertId,
  articleId,
});

export const searchExpertsRequest = (searchQuery: string, locality: string) => ({
  type: SEARCH_EXPERTS_REQUEST,
  data: { searchQuery, locality },
});

export const requestCreateExpertsDynamicLink = (id: string) => ({
  type: CREATE_EXPERTS_DYNAMIC_LINK_REQUEST,
  id,
});

export function* fetchExpertsSaga(data: any): Generator {
  try {
    // @ts-ignore
    const { data: experts }: ResponseData<Array<Expert>> = yield call(fetchExperts, data.pageNumber, data.pageSize);
    yield put(fetchExpertsSuccess(experts));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(fetchExpertsFailure(error));
  }
}

export function* fetchExpertsCategoriesSaga(): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Array<Categories>> = yield call(fetchExpertsCategories);
    yield put(fetchExpertsCategoriesSuccess(data));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(fetchExpertsCategoriesFailure(error));
  }
}

export function* createExpertSaga(expertData: ExpertsStateAction): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Expert> = yield call(createExpert, expertData.data);
    yield put(createExpertSuccess(data));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(createExpertFailed(error));
  }
}

export function* updateExpertSaga(expertData: ExpertsStateAction): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Expert> = yield call(updateExpert, expertData.expertId, expertData.data);
    yield put(updateExpertSuccess(data));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(updateExpertFailed(error));
  }
}

export function* deleteExpertSaga(expertData: ExpertsStateAction): Generator {
  try {
    yield call(deleteExpert, expertData.expertId);
    yield put(deleteExpertSuccess(expertData.expertId));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(deleteExpertFailure(error));
  }
}

export function* fetchExpertArticlesSaga(expertData: ExpertsStateAction): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<any> = yield call(fetchExpertArticles, expertData.expertId);
    yield put(({
      type: FETCH_EXPERT_ARTICLES_SUCCESS,
      data: { [expertData.expertId]: data, expertId: expertData.expertId },
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: FETCH_EXPERT_ARTICLES_FAILED,
      error,
    }));
  }
}

export function* createExpertArticlesSaga(expertData: ExpertsStateAction): Generator {
  try {
    const data: any = yield all(expertData.data.map(
      (articleId: string) => call(createExpertArticles, expertData.expertId, { articleId }),
    ));
    yield put(({
      type: CREATE_EXPERT_ARTICLES_SUCCESS,
      data: { data: data.map((i) => i.data), expertId: expertData.expertId, articleType: expertData.articleType },
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: CREATE_EXPERT_ARTICLES_FAILED,
      error,
    }));
  }
}

export function* deleteExpertArticlesSaga(expertData: ExpertsStateAction): Generator {
  try {
    // @ts-ignore
    yield call(deleteExpertArticle, expertData.expertId, expertData.articleId);
    yield put(({
      type: DELETE_EXPERT_ARTICLE_SUCCESS,
      data: { articleId: expertData.articleId, expertId: expertData.expertId },
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: DELETE_EXPERT_ARTICLE_FAILED,
      error,
    }));
  }
}

export function* searchExpertsSaga(params: any): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Array<Expert>> = yield call(searchExperts, params.data.searchQuery, params.data.locality);
    yield put(({
      type: SEARCH_EXPERTS_SUCCESS,
      data,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: SEARCH_EXPERTS_FAILED,
      error,
    }));
  }
}

export function* createExpertsDynamicLinkSaga(data: any): Generator {
  try {
    const dynamicLink: any = yield call(getDynamicLinkExperts, data.id);
    navigator.clipboard.writeText(dynamicLink.data.shortLink);
    yield put(showSuccessAlert('Link generated and copied to clipboard'));
    yield put(({
      type: CREATE_EXPERTS_DYNAMIC_LINK_SUCCESS,
      dynamicLink,
      expertId: data.id,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: CREATE_EXPERTS_DYNAMIC_LINK_FAILED,
      error,
    }));
  }
}

function* watchExperts() {
  yield takeLatest(FETCH_EXPERTS_REQUEST, fetchExpertsSaga);
  yield takeLatest(FETCH_EXPERTS_CATEGORIES_REQUEST, fetchExpertsCategoriesSaga);
  yield takeLatest(CREATE_EXPERT_REQUEST, createExpertSaga);
  yield takeLatest(UPDATE_EXPERT_REQUEST, updateExpertSaga);
  yield takeLatest(DELETE_EXPERT_REQUEST, deleteExpertSaga);
  yield takeLatest(FETCH_EXPERT_ARTICLES_REQUEST, fetchExpertArticlesSaga);
  yield takeLatest(CREATE_EXPERT_ARTICLES_REQUEST, createExpertArticlesSaga);
  yield takeLatest(DELETE_EXPERT_ARTICLE_REQUEST, deleteExpertArticlesSaga);
  yield takeLatest(SEARCH_EXPERTS_REQUEST, searchExpertsSaga);
  yield takeLatest(CREATE_EXPERTS_DYNAMIC_LINK_REQUEST, createExpertsDynamicLinkSaga);
}

export function* expertsSaga() {
  yield all([watchExperts()]);
}
