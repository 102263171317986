// @ts-nocheck
import React, { useState, FC, useMemo } from 'react';
import useGetPropertyCheckColumns from './hooks/useGetPropertyCheckColumns';
import useGetPropertyChecks, { PageSize } from './hooks/useGetPropertyChecks';
import useGridHeight from '../../../../hooks/useGridHeight';
import HIDDataGrid from '../../../../components/HIDDataGrid';
import PropertyCheckDataGridToolbar from './components/PropertyCheckDataGridToolbar';

const ListPropertyChecks: FC = () => {
  const gridHeight = useGridHeight();
  const [dataGridModifiers, setDataGridModifiers] = useState({
    rowFilter: () => true,
    columnFunc: (column) => column,
  });

  const {
    data: propertyChecks,
    loading,
  } = useGetPropertyChecks();

  const columns = useGetPropertyCheckColumns();
  const filteredPropertyChecks = useMemo(() => propertyChecks.filter(dataGridModifiers.rowFilter), [dataGridModifiers, propertyChecks]);
  const transformedColumns = useMemo(() => columns.map(dataGridModifiers.columnFunc), [dataGridModifiers]);

  return (
    <div style={{ flex: 1, height: gridHeight }}>
      <HIDDataGrid
        pageSize={PageSize}
        loading={loading}
        rows={filteredPropertyChecks}
        columns={transformedColumns}
        components={{
          Toolbar: () => <PropertyCheckDataGridToolbar onDataGridModifiersChanged={setDataGridModifiers} />,
        }}
        rowCount={filteredPropertyChecks.length}
      />
    </div>
  );
};

export default ListPropertyChecks;
