import {
  request, getAPIPath, getAdminAPIPath, unauthorizedRequest,
} from '../../../api/core';
import { AppSettings } from '../../../settings/appSettings';

const BUNDLE_ID = 'se.houseid.app';

export const fetchExperts = (pageNumber: number, pageSize: number) =>
  request(getAdminAPIPath(`experts?pageSize=${pageSize}&offset=${pageNumber * pageSize}`), {
    method: 'GET',
  });

export const fetchExpertsCategories = () => request(getAPIPath('contacts/categories'), {
  method: 'GET',
});

export const createExpert = (data: any) => request(getAdminAPIPath('experts'), {
  method: 'POST',
  data,
});

export const updateExpert = (id: string, data: any) => request(getAdminAPIPath(`experts/${id}`), {
  method: 'PATCH',
  data,
});

export const deleteExpert = (id: string) => request(getAdminAPIPath(`experts/${id}`), {
  method: 'DELETE',
});

export const fetchExpertArticles = (expertId: string) => request(getAdminAPIPath(`/experts/${expertId}/articles`), {
  method: 'GET',
});

export const createExpertArticles = (expertId: string, data: any) => request(getAdminAPIPath(`/experts/${expertId}/articles`), {
  method: 'POST',
  data,
});

export const deleteExpertArticle = (expertId: string, articleId: string) =>
  request(getAdminAPIPath(`/experts/${expertId}/articles/${articleId}`), {
    method: 'DELETE',
  });

export const searchExperts = (searchQuery: string, locality: string) => {
  const searchQueryPartI = searchQuery ? `namePrefix=${searchQuery}` : '';
  const searchQueryPartII = locality ? `&localityPrefix=${locality}` : '';
  const url = `experts?${searchQueryPartI}${searchQueryPartII}`;
  return request(getAdminAPIPath(url), { method: 'GET' });
};

export const getDynamicLinkExperts = (id: string) => {
  const config = AppSettings.firebaseConfig;
  return unauthorizedRequest(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${config.apiKey}`, {
    method: 'POST',
    data: {
      dynamicLinkInfo: {
        domainUriPrefix: `${config.domainUriPrefix}`,
        link: `https://houseid.se/mobile?action=houseid://app/expertDetails/${id}`,
        androidInfo: {
          androidPackageName: BUNDLE_ID,
        },
        iosInfo: {
          iosBundleId: BUNDLE_ID,
        },
      },
    },
  });
};
