import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch } from 'react-redux';

export default function DeleteDialog({
  id, deleteRequest, fetchRequest, deleteMessage, deleteTitle,
}: any) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOk = () => {
    setOpen(false);
    dispatch(deleteRequest(id));
    dispatch(fetchRequest());
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen} variant="contained">DELETE</Button>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">{deleteTitle}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteMessage}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>

          <Button autoFocus color="primary" onClick={handleClickOk}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
