import * as React from 'react';

import { Button } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate } from 'react-router-dom';
import DataGridToolbar from '../../../../../components/DataGridToolbar';

const ArticlesDataGridToolbar = () => {
  const navigate = useNavigate();

  const handleAddNewArticle = () => {
    navigate('/createArticle');
  };

  return (
    <DataGridToolbar>
      <Button color="primary" variant="text" onClick={handleAddNewArticle}>
        <ArticleIcon />
        Add Article
      </Button>
    </DataGridToolbar>
  );
};

export default ArticlesDataGridToolbar;
