import { UserCredential } from '@firebase/auth';
import FirebaseWrapper from '../../../services/firebase';
import { Credentials } from '../../../types/entities';

export const loginUserRequest = async (credentials: Credentials): Promise<UserCredential> =>
  FirebaseWrapper.login(credentials.email, credentials.password);

export const loginUserByTokenRequest = async (token: string): Promise<UserCredential> =>
  FirebaseWrapper.signInWithToken(token);

export const logoutUserRequest = async (): Promise<void> => FirebaseWrapper.logout();
