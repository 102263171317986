import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { AnyAction } from 'redux';
import {
  createArticle,
  deleteArticle,
  fetchArticleCategories,
  fetchArticles,
  updateArticle,
  getDynamicLink,
  fetchNews,
  searchArticles,
  fetchArticle,
} from '../api/articles';
import { Article, DynamicLink } from '../../../types/entities';
import {
  addToArticlesArray,
  updateArticlesArray,
  addToNews,
  addToCreatedNews,
  addToCreatedArticle,
  addToDynamicLinks,
} from '../../../services/articles';
import { ArticleType } from '../types/articles';
import { ActionError, ResponseData } from '../../../types/common';
import { showActionErrorAlert, showSuccessAlert } from '../../../store/reducers/alerts';

const FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST';
const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
const FETCH_ARTICLES_FAILED = 'FETCH_ARTICLES_FAILED';

const CREATE_ARTICLE_REQUEST = 'CREATE_ARTICLE_REQUEST';
const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
const CREATE_ARTICLE_FAILED = 'CREATE_ARTICLE_FAILED';

const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
const FETCH_CATEGORIES_FAILED = 'FETCH_CATEGORIES_FAILED';

const UPDATE_ARTICLE_REQUEST = 'UPDATE_ARTICLE_REQUEST';
const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS';
const UPDATE_ARTICLE_FAILED = 'UPDATE_ARTICLE_FAILED';

const DELETE_ARTICLE_REQUEST = 'DELETE_ARTICLE_REQUEST';
const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
const DELETE_ARTICLE_FAILED = 'DELETE_ARTICLE_FAILED';

const CREATE_ARTICLE_DYNAMIC_LINK_REQUEST = 'CREATE_ARTICLE_DYNAMIC_LINK_REQUEST';
const CREATE_ARTICLE_DYNAMIC_LINK_SUCCESS = 'CREATE_ARTICLE_DYNAMIC_LINK_SUCCESS';
const CREATE_ARTICLE_DYNAMIC_LINK_FAILED = 'CREATE_ARTICLE_DYNAMIC_LINK_FAILED';

const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST';
const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
const FETCH_NEWS_FAILED = 'FETCH_NEWS_FAILED';

const SEARCH_ARTICLES_REQUEST = 'SEARCH_ARTICLES_REQUEST';
const SEARCH_ARTICLES_SUCCESS = 'SEARCH_ARTICLES_SUCCESS';
const SEARCH_ARTICLES_FAILED = 'SEARCH_ARTICLES_FAILED';

const FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST';
const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
const FETCH_ARTICLE_FAILED = 'FETCH_ARTICLE_FAILED';

const ARTICLES_DYNAMIC_LINK_COPIED = 'ARTICLES_DYNAMIC_LINK_COPIED';

export interface ArticlesState {
  articles: Array<Article>,
  articlesLoading: boolean,
  articlesError: ActionError,

  loading: boolean,
  error: string,
  pageNumber: number,
  news: any,
  createdNews: any,
  newsPage: number,
  createdArticle: any,
  isNews: boolean,
  currentArticle: Article | null,
  dynamicLinks: any,
  isGenerateLinkRequestSuccess: boolean,
  isGeneratedLinkCopied: boolean,
  isDynamicLinkLoading: boolean,
}

export interface ArticlesStateAction {
  type: string;
  data: any,
  error: ActionError | null;
  articles: Array<Article>;
  article: Article,
  categories: any;
  id: string,
  dynamicLink: DynamicLink,
  expertId: string,
  newsPage: number,
  news: any,
  articleId: string,
  pageNumber: number,
  pageSize: number
}

const initState = {
  articles: [],
  articlesLoading: false,
  articlesError: null,
  articlesNumber: 0,

  categories: [],
  loading: false,
  error: '',
  pageNumber: 0,
  news: [],
  createdNews: {},
  newsPage: 0,
  createdArticle: {},

  isNews: false,
  currentArticle: null,
  dynamicLinks: [],
  isGenerateLinkRequestSuccess: false,
  isGeneratedLinkCopied: false,
  isDynamicLinkLoading: false,
};

export const articlesReducer = (
  state: ArticlesState = initState,
  action: AnyAction,
) => {
  switch (action.type) {
    case FETCH_ARTICLES_REQUEST:
      return {
        ...state,
        articlesLoading: true,
        error: null,
      };
    case FETCH_ARTICLES_SUCCESS: {
      return {
        ...state,
        articlesLoading: false,
        articles: [...state.articles, ...action.articles],
      };
    }
    case FETCH_ARTICLES_FAILED: {
      return {
        ...state,
        articlesLoading: false,
        error: action.error,
      };
    }
    case CREATE_ARTICLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        isNews: action.data.type === ArticleType.NEWS,
      };
    case CREATE_ARTICLE_SUCCESS: {
      return {
        ...state,
        articles: addToArticlesArray(state.articles, action.article),
        createdArticle: addToCreatedArticle(state.createdArticle, { data: action.article, expertId: action.expertId }),
        loading: false,
        isLoaded: true,
        news: addToNews(state.news, action.article),
        createdNews: addToCreatedNews(state.createdNews, { data: action.article, expertId: action.expertId }),
      };
    }
    case CREATE_ARTICLE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.categories,
        loading: false,
        isLoaded: true,
      };
    }
    case FETCH_CATEGORIES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case UPDATE_ARTICLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case UPDATE_ARTICLE_SUCCESS: {
      return {
        ...state,
        articles: updateArticlesArray(state.articles, action.article),
        loading: false,
        isLoaded: true,
      };
    }
    case UPDATE_ARTICLE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case DELETE_ARTICLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case DELETE_ARTICLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        articles: state.articles.filter((article) => article.id !== action.articleId),
      };
    }
    case DELETE_ARTICLE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case CREATE_ARTICLE_DYNAMIC_LINK_REQUEST:
      return {
        ...state,
        error: '',
        isGenerateLinkRequestSuccess: false,
        isDynamicLinkLoading: true,
      };
    case CREATE_ARTICLE_DYNAMIC_LINK_SUCCESS:
      return {
        ...state,
        dynamicLinks: addToDynamicLinks(state.dynamicLinks, action.articleId, action.dynamicLink),
        isGenerateLinkRequestSuccess: true,
        isDynamicLinkLoading: false,
      };
    case CREATE_ARTICLE_DYNAMIC_LINK_FAILED:
      return {
        ...state,
        error: action.error,
        isGenerateLinkRequestSuccess: false,
        isDynamicLinkLoading: false,
      };
    case FETCH_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEWS_SUCCESS:
      return {
        ...state,
        news: [...state.news, ...action.news],
        newsPage: state.newsPage + 1,
        loading: false,
        isLoaded: true,
      };
    case FETCH_NEWS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SEARCH_ARTICLES_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SEARCH_ARTICLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        articles: action.articles,
      };
    }
    case SEARCH_ARTICLES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case FETCH_ARTICLE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: '',
      };
    }
    case FETCH_ARTICLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoaded: true,
        currentArticle: action.article,
      };
    }
    case FETCH_ARTICLE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case ARTICLES_DYNAMIC_LINK_COPIED:
      return {
        ...state,
        isGeneratedLinkCopied: true,
      };
    default:
      return state;
  }
};

export const fetchArticlesRequest = (pageNumber: number, pageSize: number): ArticlesStateAction =>
  ({ type: FETCH_ARTICLES_REQUEST, pageNumber, pageSize } as ArticlesStateAction);

export const fetchArticlesSuccess = (
  articles: Array<Article> | null,
): ArticlesStateAction => ({ type: FETCH_ARTICLES_SUCCESS, articles } as ArticlesStateAction);

export const fetchArticlesFailure = (error: ActionError): ArticlesStateAction => ({ type: FETCH_ARTICLES_FAILED, error } as ArticlesStateAction);

export const createArticleRequest = (data: any, expertId: string): ArticlesStateAction =>
  ({ type: CREATE_ARTICLE_REQUEST, data, expertId } as ArticlesStateAction);

export const createArticleSuccess = (
  article: Article,
  expertId: string,
): any => ({ type: CREATE_ARTICLE_SUCCESS, article, expertId });

export const createArticleFailure = (error: ActionError): ArticlesStateAction => ({ type: FETCH_ARTICLES_FAILED, error } as ArticlesStateAction);

export const fetchCategoriesRequest = (): ArticlesStateAction => ({ type: FETCH_CATEGORIES_REQUEST } as ArticlesStateAction);

export const fetchCategoriesSuccess = (categories: any): ArticlesStateAction => ({ type: FETCH_CATEGORIES_SUCCESS, categories } as ArticlesStateAction);

export const fetchCategoriesFailure = (error: ActionError): ArticlesStateAction => ({ type: FETCH_CATEGORIES_FAILED, error } as ArticlesStateAction);

export const updateArticleRequest = (data: any, id: string, expertId: string): ArticlesStateAction => ({
  type: UPDATE_ARTICLE_REQUEST, data, id, expertId,
} as ArticlesStateAction);

export const updateArticleSuccess = (
  article: Article | null,
  expertId: string,
): ArticlesStateAction => ({ type: UPDATE_ARTICLE_SUCCESS, article, expertId } as ArticlesStateAction);

export const updateArticleFailure = (error: ActionError): ArticlesStateAction => ({ type: UPDATE_ARTICLE_FAILED, error } as ArticlesStateAction);

export const deleteArticleRequest = (id: string): ArticlesStateAction => ({ type: DELETE_ARTICLE_REQUEST, id } as ArticlesStateAction);

export const deleteArticleSuccess = (
  articleId: string,
): ArticlesStateAction => ({ type: DELETE_ARTICLE_SUCCESS, articleId } as ArticlesStateAction);

export const deleteArticleFailure = (error: ActionError): ArticlesStateAction => ({ type: DELETE_ARTICLE_FAILED, error } as ArticlesStateAction);

export const requestCreateDynamicLink = (link: string, id: string) => ({
  type: CREATE_ARTICLE_DYNAMIC_LINK_REQUEST,
  data: { link, id },
});

export const createDynamicLinkSuccess = (dynamicLink: DynamicLink, articleId: string) => ({
  type: CREATE_ARTICLE_DYNAMIC_LINK_SUCCESS,
  dynamicLink,
  articleId,
});

export const createDynamicLinkFailed = (error: ActionError) => ({
  type: CREATE_ARTICLE_DYNAMIC_LINK_FAILED,
  error,
});

export const fetchNewsRequest = (pageNumber: any): ArticlesStateAction => ({ type: FETCH_NEWS_REQUEST, pageNumber } as ArticlesStateAction);

export const searchArticlesRequest = (searchQuery: string) => ({ type: SEARCH_ARTICLES_REQUEST, data: searchQuery });

export const fetchArticleRequest = (id: string) => ({
  type: FETCH_ARTICLE_REQUEST,
  data: id,
});

export const copyArticlesGeneratedLinkRequest = () => ({
  type: ARTICLES_DYNAMIC_LINK_COPIED,
});

export function* fetchArticlesSaga(usersStateAction: ArticlesStateAction): Generator {
  try {
    // @ts-ignore
    const response: ResponseData<Array<Article>> = yield call(fetchArticles, usersStateAction.pageNumber, usersStateAction.pageSize);
    yield put(fetchArticlesSuccess(response.data));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(fetchArticlesFailure(error));
  }
}

export function* createArticleSaga(usersStateAction: ArticlesStateAction): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Article> = yield call(createArticle, usersStateAction.data);
    yield put(createArticleSuccess(data, usersStateAction.expertId));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(createArticleFailure(error));
  }
}

export function* fetchArticleCategoriesSaga(): Generator {
  try {
    // @ts-ignore
    const { data }: any = yield call(fetchArticleCategories);
    yield put(fetchCategoriesSuccess(data));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(fetchCategoriesFailure(error));
  }
}

export function* updateArticleSaga(usersStateAction: ArticlesStateAction): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Article> = yield call(updateArticle, usersStateAction.data, usersStateAction.id);

    yield put(updateArticleSuccess(data, usersStateAction.expertId));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(updateArticleFailure(error));
  }
}

export function* deleteArticleSaga(usersStateAction: ArticlesStateAction): Generator {
  try {
    yield call(deleteArticle, usersStateAction.id);
    yield put(deleteArticleSuccess(usersStateAction.id));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(deleteArticleFailure(error));
  }
}

export function* createArticleDynamicLink(data: any): Generator {
  try {
    const dynamicLink: any = yield call(getDynamicLink, data.data.link, data.data.id);
    navigator.clipboard.writeText(dynamicLink.data.shortLink);

    yield put(showSuccessAlert('Link generated and copied to clipboard'));
    yield put(createDynamicLinkSuccess(dynamicLink, data.data.id));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(createDynamicLinkFailed(error));
  }
}

export function* fetchNewsSaga(usersStateAction: ArticlesStateAction): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Array<Article>> = yield call(fetchNews, usersStateAction.pageNumber);
    yield put(({
      type: FETCH_NEWS_SUCCESS,
      news: data,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: FETCH_NEWS_FAILED,
      error,
    }));
  }
}

export function* searchArticlesSaga(usersStateAction: ArticlesStateAction): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Array<Article>> = yield call(searchArticles, usersStateAction.data);
    yield put(({
      type: SEARCH_ARTICLES_SUCCESS,
      articles: data,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: SEARCH_ARTICLES_FAILED,
      error,
    }));
  }
}

export function* fetchArticleSaga(usersStateAction: ArticlesStateAction): Generator {
  try {
    // @ts-ignore
    const { data }: ResponseData<Article> = yield call(fetchArticle, usersStateAction.data);
    yield put(({
      type: FETCH_ARTICLE_SUCCESS,
      article: data,
    }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(({
      type: FETCH_ARTICLE_FAILED,
      error,
    }));
  }
}

function* watchArticles() {
  yield takeLatest(FETCH_ARTICLES_REQUEST, fetchArticlesSaga);
  yield takeLatest(CREATE_ARTICLE_REQUEST, createArticleSaga);
  yield takeLatest(FETCH_CATEGORIES_REQUEST, fetchArticleCategoriesSaga);
  yield takeLatest(UPDATE_ARTICLE_REQUEST, updateArticleSaga);
  yield takeLatest(DELETE_ARTICLE_REQUEST, deleteArticleSaga);
  yield takeLatest(CREATE_ARTICLE_DYNAMIC_LINK_REQUEST, createArticleDynamicLink);
  yield takeLatest(FETCH_NEWS_REQUEST, fetchNewsSaga);
  yield takeLatest(SEARCH_ARTICLES_REQUEST, searchArticlesSaga);
  yield takeLatest(FETCH_ARTICLE_REQUEST, fetchArticleSaga);
}

export function* articlesSaga() {
  yield all([watchArticles()]);
}
