export enum PropertyCheckPaymentMethod {
  INVOICE = 'invoice',
  PAYMENT_PLAN = 'payment-plan',
}

export enum PropertyCheckArticleType {
  WITH_BASIC_INFO = 'with-basic-info',
  WITHOUT_BASIC_INFO = 'without-basic-info',
}

export interface AddPropertyCheckRequestData {
  userId?: string,
  propertyId?: string,
  name: string,
  phoneNumber: string,
  email: string,
  streetAddress: string,
  postalCode: string,
  addressLocality: string,
  addressCountry: string,
  requestedDate: string,
  articleId: PropertyCheckArticleType,
  price: string,
  paymentMethodId: PropertyCheckPaymentMethod,
  campaignName: string,
}
