import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CreateProductFormik from './components/CreateProductOfferFormik';
import { ProductOfferPageProps } from '../../../../types/productOfferProperties';

export default function CreateArticle() {
  const { expertId, id }: ProductOfferPageProps = useParams();
  const { pathname } = useLocation();
  return <CreateProductFormik expertId={expertId} id={id} pathname={pathname} />;
}
