import { ProductOffer } from '../types/productOfferProperties';

export const updateProductOfferItem = (productOffers: Array<ProductOffer>, offer: ProductOffer) =>
  productOffers.map((itm) => (itm.id === offer.id ? offer : itm));

export const deleteProductOfferItem = (productOffers: Array<ProductOffer>, id: string) =>
  productOffers.filter((offer: ProductOffer) => offer.id !== id);

export const addProductOfferItem = (productOffers: Array<ProductOffer>, offer: ProductOffer) => {
  productOffers.push(offer);
  return productOffers;
};
