/* eslint-disable max-len */
import { initializeApp } from '@firebase/app';
import {
  Auth,
  getAuth,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from '@firebase/auth';
import { AppSettings } from '../../settings/appSettings';

class Firebase {
  auth: Auth;

  constructor() {
    const app = initializeApp(AppSettings.firebaseConfig);
    this.auth = getAuth(app);
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  signInWithToken(token: string) {
    return signInWithCustomToken(this.auth, token);
  }

  reloadUser() {
    return this.auth.currentUser?.reload();
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  logout() {
    return this.auth.signOut();
  }

  getAccessToken() {
    return this.auth.currentUser?.getIdToken();
  }

  forceRefreshAccessToken() {
    return this.auth.currentUser?.getIdToken(true);
  }

  isAuthenticated() {
    return this.auth.currentUser !== null;
  }

  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }
}

export default new Firebase();
