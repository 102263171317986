import * as React from 'react';

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import DataGridToolbar from '../../../../../components/DataGridToolbar';

const ArticlesDataGridToolbar = () => {
  const navigate = useNavigate();

  const handleAddNewArticle = () => {
    navigate('/experts/create');
  };

  return (
    <DataGridToolbar>
      <Button color="primary" variant="text" onClick={handleAddNewArticle}>
        <BusinessIcon />
        Add expert
      </Button>
    </DataGridToolbar>
  );
};

export default ArticlesDataGridToolbar;
