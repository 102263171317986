import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers';
import { QueryHook } from '../../../../../types/common';
import { fetchPropertyChecksRequest } from '../../../reducers/propertyCheck';

export const PageSize = 50;

type UseGetPropertyChecks = () => QueryHook<Array<Object>>;

const useGetPropertyChecks: UseGetPropertyChecks = () => {
  const dispatch = useDispatch();

  const propertyChecks = useSelector(({ propertyCheck }: RootState) => propertyCheck.propertyChecks);
  const loading = useSelector(({ propertyCheck }: RootState) => propertyCheck.propertyChecksLoading);
  const fetchError = useSelector(({ propertyCheck }: RootState) => propertyCheck.propertyChecksError);

  const fetch = () => {
    if (propertyChecks.length === 0 && !loading) {
      dispatch(fetchPropertyChecksRequest());
    }
  };

  useEffect(() => {
    if (!loading && _.isEmpty(propertyChecks)) {
      fetch();
    }
  }, []);

  return {
    fetch,
    refetch: () => fetch(),
    data: propertyChecks,
    loading,
    error: fetchError,
  };
};

export default useGetPropertyChecks;
