import React, { useState } from 'react';
import useGetArticleColumns from './hooks/useArticleColumns';
import ArticlesDataGridToolbar from './components/ArticlesDataGridToolbar';
import useGridHeight from '../../../../hooks/useGridHeight';
import HIDDataGrid from '../../../../components/HIDDataGrid';
import useGetArticles, { PageSize } from '../../hooks/useGetArticles';

const ListArticles = () => {
  const gridHeight = useGridHeight();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(PageSize);

  const {
    data: articles,
    loading,
    fetch,
  } = useGetArticles();

  const articleGridColumns = useGetArticleColumns();

  return (
    <div style={{ flex: 1, height: gridHeight }}>
      <HIDDataGrid
        pageSize={pageSize}
        page={pageNumber}
        loading={loading}
        rows={articles}
        columns={articleGridColumns}
        rowCount={1000}
        components={{
          Toolbar: ArticlesDataGridToolbar,
        }}
        onPageChange={(newPageNumber) => {
          setPageNumber(newPageNumber);
          fetch(newPageNumber, pageSize);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          fetch(pageNumber, newPageSize);
        }}
      />
    </div>
  );
};

export default ListArticles;
