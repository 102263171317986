import React, { useState } from 'react';

import useExpertColumns from './hooks/useExpertColumns';
import ExpertsDataGridToolbar from './components/ExpertsDataGridToolbar';
import useGridHeight from '../../../../hooks/useGridHeight';
import HIDDataGrid from '../../../../components/HIDDataGrid';
import useGetExperts, { PageSize } from '../../hooks/useGetExperts';

function ListExperts() {
  const gridHeight = useGridHeight();
  const expertGridColumns = useExpertColumns();
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(PageSize);

  const {
    data: experts,
    loading,
    fetch,
  } = useGetExperts();

  return (
    <div style={{ flex: 1, height: gridHeight }}>
      <HIDDataGrid
        pageSize={pageSize}
        page={pageNumber}
        loading={loading}
        rows={experts}
        columns={expertGridColumns}
        rowCount={1000}
        components={{
          Toolbar: ExpertsDataGridToolbar,
        }}
        onPageChange={(newPageNumber: number) => {
          setPageNumber(newPageNumber);
          fetch(newPageNumber, pageSize);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          fetch(pageNumber, newPageSize);
        }}
      />
    </div>
  );
}

export default ListExperts;
