import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { QueryHook } from '../../../types/common';
import { Article } from '../../../types/entities';
import { fetchArticlesRequest } from '../reducers/articles';

export const PageSize = 100;

type UseGetArticles = () => QueryHook<Array<Article>>;

const useGetArticles: UseGetArticles = () => {
  const dispatch = useDispatch();

  const articles = useSelector(({ articles }: RootState) => articles.articles);

  const loading = useSelector(({ articles }: RootState) => articles.articlesLoading);
  const articlesError = useSelector(({ articles }: RootState) => articles.articlesError);

  const fetch = (pageNumber: number = 0, pageSize = PageSize) => {
    if (articles.length <= pageNumber * pageSize) {
      dispatch(fetchArticlesRequest(pageNumber, pageSize));
    }
  };

  useEffect(() => {
    if (!loading && _.isEmpty(articles)) {
      fetch();
    }
  }, []);

  return {
    fetch,
    refetch: () => fetch(0),
    data: articles,
    loading,
    error: articlesError,
  };
};

export default useGetArticles;
