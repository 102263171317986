// @ts-nocheck
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { FormikProps } from 'formik';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Rings as Loader } from 'react-loader-spinner';
import { FormValues, MyFormProps, ArticleType } from '../../../types/articles';
import DropDown from '../../../../../components/DropDown';
import SubmitButton from '../../../../../components/SubmitButton';

export default function InnerForm(props: MyFormProps & FormikProps<FormValues>) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 10,
      justifyContent: 'center',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, values, setFieldValue, dirty,
    fetchCategoriesRequest, categories, requestCreateDynamicLink, pathname, fetchArticleRequest,
    articlesLoading, id,
    isDynamicLinkLoading,
  } = props;
  const {
    title, description, image, link, sourceUrl, sourceName, articleCategories, language, dynamicLink, type, inspirationsList, expertsList,
  } = values;
  const params = useParams();
  const [articleLanguage, setArticleLanguage] = useState(language);
  // @ts-ignore
  const [selectedCategories, setSelectedCategories] = useState(
    categories?.filter((category: any) => articleCategories?.indexOf(category.id) >= 0));

  const loadArticle = useCallback(async (id: string) => dispatch(fetchArticleRequest(id)), [id]);

  useEffect(() => {
    if (id) {
      loadArticle(id);
    }
  }, [id]);

  useEffect(() => {
    dispatch(fetchCategoriesRequest());
  }, []);

  const navigate = useNavigate();

  const getName = () => (pathname.indexOf('createNews') >= 0 || pathname.indexOf('editNews') >= 0
    ? Object.hasOwnProperty.call(params, 'id') ? 'Edit news' : 'Create news'
    : Object.hasOwnProperty.call(params, 'id') ? 'Edit article' : 'Create article');

  return (
    <Container component="main" maxWidth="xl">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {getName()}
        </Typography>

        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            if (Object.keys(errors).length === 0) {
              // @ts-ignore
              handleSubmit(values);
              // TODO Refactor
              const badCondition = pathname.indexOf('createNews') >= 0
                || pathname.indexOf('createExpertArticle') >= 0
                || pathname.indexOf('editNews') >= 0
                || pathname.indexOf('editExpertArticle') >= 0;

              if (badCondition) {
                navigate(-1);
              } else {
                navigate('/articles');
              }
            }
          }}
        >
          {articlesLoading ? (
            <Container className={classes.row} maxWidth="xl">
              <Loader color="gray" height={80} type="ThreeDots" width={80} />
            </Container>
          ) : (
            <Container maxWidth="xl">
              <Container className={classes.row} maxWidth="xl">
                <Container maxWidth="xs">
                  {id ? (
                    <FormControl className={classes.formControl}>
                      <TextField
                        disabled
                        fullWidth
                        label="ID"
                        margin="normal"
                        name="ID"
                        value={id}
                        variant="standard"
                      />

                      {isDynamicLinkLoading
                        ? (<Loader color="gray" height={80} type="ThreeDots" width={80} />) : (
                          <TextField
                            disabled
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="DynamicLink"
                            margin="normal"
                            name="DynamicLink"
                            value={dynamicLink?.shortLink}
                            variant="standard"
                          />
                        )}

                      <div>
                        <Button
                          className={classes.submit}
                          color="primary"
                          disabled={!link || !id}
                          fullWidth
                          onClick={() => {
                            requestCreateDynamicLink(link, id);
                          }}
                          variant="contained"
                        >
                          Generate link
                        </Button>
                      </div>
                    </FormControl>
                  ) : null}

                  <TextField
                    error={(touched.title ? Object.hasOwnProperty.call(errors, 'title') : null)}
                    fullWidth
                    helperText={(touched.title && errors.title) ? errors.title : null}
                    label="Title"
                    margin="normal"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={title}
                    variant="standard"
                  />

                  <TextField
                    error={(touched.description ? Object.hasOwnProperty.call(errors, 'description') : null)}
                    fullWidth
                    helperText={(touched.description && errors.description) ? errors.description : null}
                    label="Description"
                    margin="normal"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={description}
                    variant="standard"
                  />

                  <TextField
                    error={(touched.sourceUrl ? Object.hasOwnProperty.call(errors, 'sourceUrl') : null)}
                    fullWidth
                    helperText={(touched.sourceUrl && errors.sourceUrl) ? errors.sourceUrl : null}
                    label="SourceUrl"
                    margin="normal"
                    name="sourceUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={sourceUrl}
                    variant="standard"
                  />
                </Container>

                <Container maxWidth="xs">
                  <TextField
                    error={(touched.image ? Object.hasOwnProperty.call(errors, 'image') : null)}
                    fullWidth
                    helperText={(touched.image && errors.image) ? errors.image : null}
                    label="Image"
                    margin="normal"
                    name="image"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={image}
                    variant="standard"
                  />

                  <TextField
                    error={(touched.link ? Object.hasOwnProperty.call(errors, 'link') : null)}
                    fullWidth
                    helperText={(touched.link && errors.link) ? errors.link : null}
                    label="Link"
                    margin="normal"
                    name="link"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={link}
                    variant="standard"
                  />

                  <TextField
                    error={(touched.sourceName ? Object.hasOwnProperty.call(errors, 'sourceName') : null)}
                    fullWidth
                    helperText={(touched.sourceName && errors.sourceName) ? errors.sourceName : null}
                    label="SourceName"
                    margin="normal"
                    name="sourceName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={sourceName}
                    variant="standard"
                  />

                  <FormControlLabel
                    control={<Checkbox checked={inspirationsList} color="primary" name="inspirationsList" onChange={handleChange} />}
                    label="Inspirations list"
                  />

                  <FormControlLabel
                    color="primary"
                    control={(
                      <Checkbox
                        checked={expertsList}
                        color="primary"
                        disabled={pathname.indexOf('/experts') >= 0}
                        name="expertsList"
                        onChange={handleChange}
                      />
                    )}
                    label="Experts list"
                  />
                </Container>
              </Container>

              <Container className={classes.row} maxWidth="xl">
                <Container maxWidth="xs">
                  <DropDown
                    itemId="value"
                    itemText="label"
                    itemValue="value"
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setArticleLanguage(event.target.value as string);
                      setFieldValue('language', event.target.value);
                    }}
                    options={[{ value: 'sv', label: 'Swedish' }, { value: 'en', label: 'English' }]}
                    selectedOptions={articleLanguage}
                    title="Language"
                    value={articleLanguage}
                  />
                </Container>

                {type === ArticleType.LINK && (
                  <Container maxWidth="xs">
                    <DropDown
                      errors={Object.hasOwnProperty.call(errors, 'expertCategories') && errors.articleCategories}
                      isMultiSelect
                      itemId="id"
                      itemText="name"
                      itemValue="id"
                      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        const values = event.target.value as Array<string>;
                        const selectedCategory = categories.filter((category: any) => values.indexOf(category.id) >= 0);
                        setSelectedCategories(selectedCategory);
                        setFieldValue('articleCategories', selectedCategory?.map((artCategory: any) => artCategory.id));
                      }}
                      options={categories}
                      selectedOptions={selectedCategories}
                      title="Categories"
                      value={selectedCategories?.map((category: any) => category.id || category)}
                    />
                  </Container>
                )}
              </Container>
            </Container>
          )}

          <SubmitButton
            disabled={isSubmitting || Object.keys(errors).length > 0 || !dirty}
            title={Object.hasOwnProperty.call(params, 'id') ? 'Save' : 'Create'}
          />
        </form>
      </div>
    </Container>
  );
}
