import jwtDecode from 'jwt-decode';
import {
  put, takeLatest, all, call, apply,
} from 'redux-saga/effects';
import { showActionErrorAlert } from '../../../store/reducers/alerts';
import { ActionError } from '../../../types/common';
import { CurrentUser, Credentials } from '../../../types/entities';
import { loginUserRequest, logoutUserRequest } from '../api/auth';
import FirebaseWrapper from '../../../services/firebase';

const LOGIN_BY_TOKEN_USER = 'LOGIN_BY_TOKEN_USER';
const LOGIN_USER = 'LOGIN_USER';
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

const LOGOUT_USER = 'LOGOUT_USER';
const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export interface AuthState {
  currentUser?: CurrentUser
}
export interface AuthStateAction {
  credentials: Credentials | null;
  type: string;
  error?: ActionError;
  currentUser: any;
}

const verifyIfAdmin = (token: string) =>
  (token && Object.hasOwnProperty.call(jwtDecode(token), 'urn:houseid:admin')) || false;

const initState = {
  currentUser: null,
  loading: false,
  isLoggedIn: false,
  error: '',
  token: null,
};

export const authReducer = (
  state: AuthState = initState,
  action: AuthStateAction,
) => {
  switch (action.type) {
    case LOGIN_BY_TOKEN_USER:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        currentUser: action.currentUser,
        token: action.currentUser?.token,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        currentUser: null,
        token: null,
      };
    case LOGOUT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const loginUser = (
  credentials: Credentials,
): AuthStateAction => ({ type: LOGIN_USER, credentials } as AuthStateAction);

export const loginUserSuccess = (
  currentUser: CurrentUser | null,
): AuthStateAction => ({ type: LOGIN_USER_SUCCESS, currentUser } as AuthStateAction);

export const loginUserFailure = (error: ActionError): AuthStateAction => ({ type: LOGIN_USER_FAILURE, error } as AuthStateAction);

export function* loginUserSaga(usersStateAction: AuthStateAction): Generator {
  try {
    // @ts-ignore
    const currentUser: any = yield call(
      loginUserRequest,
      usersStateAction.credentials,
    );
    const token: any = yield currentUser.user.getIdToken();
    const isAdmin = verifyIfAdmin(token);
    if (!isAdmin) {
      const error = {
        message: 'The user should have admin permissions',
        code: '401',
      };
      yield put(showActionErrorAlert(error));
      yield put(loginUserFailure(error));
      return;
    }
    yield put(loginUserSuccess({ ...currentUser.user.toJSON(), token }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(loginUserFailure(error.message));
  }
}

export const loginUserByToken = (): AuthStateAction => ({ type: LOGIN_BY_TOKEN_USER } as AuthStateAction);

export function* loginUserByTokenSaga(): Generator {
  try {
    const currentUser: any = yield apply(
      FirebaseWrapper,
      FirebaseWrapper.isInitialized,
      [],
    );

    if (!currentUser) {
      yield put(loginUserSuccess(currentUser));
      return;
    }
    const token: any = yield currentUser.getIdToken();
    const isAdmin = verifyIfAdmin(token);
    if (!isAdmin) {
      const error = {
        message: 'The user should have admin permissions',
        code: '401',
      };
      yield put(showActionErrorAlert(error));
      yield put(loginUserFailure(error));
      return;
    }
    yield put(loginUserSuccess({ ...currentUser.toJSON(), token }));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(loginUserFailure(error.message));
  }
}

export const logoutUser = (): AuthStateAction => ({ type: LOGOUT_USER } as AuthStateAction);

export const logoutUserSuccess = (): AuthStateAction => ({ type: LOGOUT_USER_SUCCESS } as AuthStateAction);

export const logoutUserFailure = (error: ActionError): AuthStateAction => ({ type: LOGOUT_USER_FAILURE, error } as AuthStateAction);

export function* logoutUserSaga(): Generator {
  try {
    yield call(logoutUserRequest);
    yield put(logoutUserSuccess());
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(logoutUserFailure(error.message));
  }
}

function* watchLogin() {
  yield takeLatest(LOGIN_BY_TOKEN_USER, loginUserByTokenSaga);
  yield takeLatest(LOGIN_USER, loginUserSaga);
  yield takeLatest(LOGOUT_USER, logoutUserSaga);
}

export function* authSaga() {
  yield all([watchLogin()]);
}
