import React from 'react';
import {
  FormControl, InputLabel, Select, Input, MenuItem, FormHelperText, Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function DropDown({
  value, onChange, options, error, itemId, itemValue, itemText, selectedOptions, isMultiSelect, title,
}: any) {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
  }));

  const classes = useStyles();
  function getStylesCategories(id: string, theme: Theme) {
    return {
      fontWeight:
        typeof selectedOptions?.map === 'function' && selectedOptions?.map((category: any) => category.id).indexOf(id) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  return (
    <FormControl className={classes.formControl} error={error}>
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>

      <Select
        id="demo-mutiple-name"
        input={<Input />}
        labelId="demo-mutiple-name-label"
        MenuProps={MenuProps}
        multiple={isMultiSelect}
        onChange={onChange}
        value={value}
      >
        {options?.map((item: any) => (
          <MenuItem key={item[itemId]} style={isMultiSelect ? getStylesCategories(item[itemId], theme) : null} value={item[itemValue]}>
            {item[itemText]}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}

export default DropDown;
