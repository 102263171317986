import _ from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { AlertData } from '../store/reducers/alerts';

type UseGetAlerts = () => AlertData;

const useGetAlert: UseGetAlerts = () => {
  const alerts = useSelector(({ alerts }: RootState) => alerts.alerts);

  return _.first(alerts);
};

export default useGetAlert;
