/* eslint-disable @typescript-eslint/no-use-before-define */
// @ts-nocheck
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import {
  Container, TextField, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { CONDOMINIUM, HOUSE, UCAddress } from '../../../../types/verifyPropertyProperties';
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback';
import useGetUCAddresses from '../../hooks/useGetUCAddresses';
import { verifyPropertyRequest } from '../../reducers/verifyProperty';
import { RootState } from '../../../../store/reducers';
import useOnStateChange from '../../../../hooks/useOnStateChange';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  radioButtons: {
    marginTop: theme.spacing(2),
  },
}));

const schema = Yup.object().shape({
  propertyId: Yup.string().required('property required').min(10, 'PropertyId is too short, 10 chars minimum'),
  propertyType: Yup.string().required('subject required'),
  apartmentNumber: Yup.number().when('propertyType', {
    is: CONDOMINIUM,
    then: Yup.number().required('Must enter apartment number'),
  }),
  objectId: Yup.string().required('property required'),
});

const VerifyPropertyForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [query, setQuery] = useState('');
  const [debouncedQuery, debouncedQuerySetter] = useState('');
  const setDebouncedQuery = useDebouncedCallback(debouncedQuerySetter);

  const {
    data: addresses,
    loading: addressesLoading,
  } = useGetUCAddresses(debouncedQuery);

  const handleQueryChange = (newQuery: string) => {
    setQuery(newQuery);
    setDebouncedQuery(newQuery);
  };

  const {
    values, touched, errors, dirty, submitForm, handleBlur, handleChange, setFieldValue, resetForm,
  } = useFormik({
    validationSchema: schema,
    initialValues: {
      propertyId: '', propertyType: '', apartmentNumber: '', objectId: '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const {
        propertyId, objectId, propertyType, apartmentNumber,
      } = values;
      dispatch(verifyPropertyRequest(propertyId, { type: propertyType, objectId, apartmentNumber: Number.parseInt(apartmentNumber, 10) }));
      proceedAfterSubmit();
    },
  });

  const verifyingPropertyError = useSelector(({ verifyProperty }: RootState) => verifyProperty.verifyingPropertyError);
  const isVerifyingProperty = useSelector(({ verifyProperty }: RootState) => verifyProperty.isVerifyingProperty);

  const resetFormIfSuccess = () => {
    if (verifyingPropertyError) {
      return;
    }
    handleQueryChange('');
    resetForm();
  };

  const proceedAfterSubmit = useOnStateChange(
    ({ verifyProperty }: RootState) => verifyProperty.isVerifyingProperty,
    resetFormIfSuccess,
  );

  const {
    apartmentNumber, propertyId, propertyType, objectId,
  } = values;

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Verify property
        </Typography>

        <form
          className={classes.formControl}
          onSubmit={(e: any) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <TextField
            autoFocus
            error={(errors?.propertyId && touched?.propertyId) || false}
            fullWidth
            helperText={(errors.propertyId && touched.propertyId) ? errors.propertyId : null}
            label="PropertyId"
            margin="normal"
            name="propertyId"
            onBlur={handleBlur}
            onChange={handleChange}
            value={propertyId}
            variant="standard"
          />

          <Autocomplete
            autoComplete
            getOptionLabel={(address: UCAddress) => address?.name || ''}
            isOptionEqualToValue={(option: UCAddress, value: UCAddress) => option?.id === value?.id}
            id="combo-box-demo"
            includeInputInList
            loading={addressesLoading}
            onChange={(event, newInputValue) => {
              setFieldValue('objectId', newInputValue?.id);
            }}
            onInputChange={(event, newInputValue) => {
              handleQueryChange(newInputValue);
            }}
            options={addresses || []}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Address"
                margin="normal"
                variant="standard"
              />
            )}
            value={{ id: objectId, name: query }}
          />

          <FormControl className={classes.radioButtons} component="fieldset">
            <FormLabel component="legend">Property Type</FormLabel>

            <RadioGroup
              aria-label="propertyType"
              name="propertyType"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('propertyType', (event.target as HTMLInputElement).value);
              }}
              value={propertyType}
            >
              <FormControlLabel control={<Radio color="primary" />} label="House" value={HOUSE} />

              <FormControlLabel control={<Radio color="primary" />} label="Condominium" value={CONDOMINIUM} />
            </RadioGroup>
          </FormControl>

          {propertyType === CONDOMINIUM && (
            <TextField
              fullWidth
              label="Apartment Number"
              margin="normal"
              name="apartmentNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              rows={5}
              value={apartmentNumber}
              variant="standard"
            />
          )}

          <Container maxWidth="xs">
            <LoadingButton
              loading={isVerifyingProperty}
              className={classes.submit}
              color="primary"
              disabled={isVerifyingProperty || Object.keys(errors).length > 0 || !dirty}
              fullWidth
              type="submit"
              variant="contained"
            >
              Verify Property
            </LoadingButton>
          </Container>
        </form>
      </div>
    </Container>
  );
};

export default VerifyPropertyForm;
