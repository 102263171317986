import { KeyValueObject } from '../../../../../types/common';

export enum DataType {
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  LIST = 'list',
}

export type FieldInfo = {
  id: string;
  label: string;
  required: boolean;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  error: string;
  touched: boolean;
  type: DataType;
  autoFocus: boolean;
  options: Array<KeyValueObject>
};
