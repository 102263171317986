// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Button, makeStyles, Box, Divider, Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Rings as Loader } from 'react-loader-spinner';
import CustomGrid from '../../../../../components/CustomGrid';
import SearchDialog from '../../../../../components/SearchDialog';
import { ArticleType } from '../../../../Articles/types/articles';

function Section({
  id, columns, entity, expertEntity, loadExpertEntity, createEntityRequestFunc,
  type, onClick, title, rowsFunc, loadEntity, loading, buttonTitle,
}: any) {
  const [selectedEntity, setSelectedEntity] = useState([]);

  useEffect(() => {
    if (loadEntity && entity?.length === 0) {
      loadEntity();
    }
  }, []);

  useEffect(() => {
    if (id) {
      loadExpertEntity(id);
    }
  }, []);

  useEffect(() => {
    if (expertEntity.length >= 0) {
      setSelectedEntity(expertEntity?.filter((item: any) => item.type === type).filter((item: any) => item).map((itm: any) => (itm.title
        ? itm : entity.find((item: any) => itm.id === item.id))));
    }
  }, [expertEntity, id, expertEntity?.length, expertEntity.title, expertEntity.ingress]);

  const useStyles = makeStyles((theme) => ({
    centeredRow: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      alignItems: 'flex-start',
      marginLeft: theme.spacing(3),
    },
    grid: { marginLeft: theme.spacing(2), marginBottom: theme.spacing(4), 'min-height': 380 },
    marginRight: {
      marginRight: theme.spacing(4),
    },
    mainContainer: { flex: 1, display: 'flex', justifyContent: 'center' },
  }));

  const classes = useStyles();
  return (
    <div style={{ width: '100%' }}>
      <Typography component="h3" variant="h6">
        {title}
      </Typography>

      <Divider />

      <Box className={classes.centeredRow} maxWidth="xs">
        <Button
          className={classes.marginRight}
          color="primary"
          disabled={!id}
          onClick={onClick}
          startIcon={<AddIcon />}
          variant="contained"
        >
          {buttonTitle}
        </Button>

        {type === ArticleType.LINK && (
          <SearchDialog
            buttonTitle="Add articles"
            columns={columns}
            data={
              entity.filter(
                (article: any) => article.type === type && (article?.data.expertsList || article.data?.expertsList === undefined))
                .map(({ id, title, ingress }: any) => ({ id, title, ingress }))
                .filter((itm: any) => selectedEntity.findIndex((entity: any) => entity.id === itm.id) < 0)}
            disabled={!id}
            pickedItemsSetter={(selectedIds: any) => {
              const selected = selectedIds.flatMap((id: string) => entity.filter((article: any) => article.id === id));
              const createIds = selectedIds.filter((id: string) => selectedEntity.findIndex((entity: any) => entity.id === id) < 0);
              // @ts-ignore
              setSelectedEntity([...selected, ...selectedEntity]);
              createEntityRequestFunc(id, createIds, type);
            }}
            searchTitle="Articles"
            selectionModel={selectedEntity.map((item: any) => item.id)}
            supportMultiSelection
          />
        )}
      </Box>

      {
        loading ? (
          <div className={classes.mainContainer}>
            <Loader color="gray" height={80} type="ThreeDots" width={80} />
          </div>
        ) : (
          <CustomGrid
            className={classes.grid}
            columns={columns}
            pageSize={5}
            rows={rowsFunc(selectedEntity)}
          />
        )
      }

    </div>
  );
}

export default Section;
