import { all } from 'redux-saga/effects';
import { articlesSaga as articles } from '../../modules/Articles/reducers/articles';
import { authSaga as auth } from '../../modules/User/reducers/auth';
import { expertsSaga as experts } from '../../modules/Experts/reducers/experts';
import { blobSaga } from '../reducers/blob';
import { messagesSaga } from '../../modules/Actions/reducers/messages';
import { productOfferSaga } from '../../modules/Experts/reducers/productOffer';
import { verifyPropertySaga } from '../../modules/Actions/reducers/verifyProperty';
import { alertsSaga } from '../reducers/alerts';
import { propertyCheckSaga } from '../../modules/PropertyChecks/reducers/propertyCheck';

export default function* () {
  yield all([
    auth(),
    articles(),
    experts(),
    blobSaga(),
    messagesSaga(),
    productOfferSaga(),
    verifyPropertySaga(),
    alertsSaga(),
    propertyCheckSaga(),
  ]);
}
