export const AppSettings = process.env.REACT_APP_FIREBASE_PROJECT === 'production'
  ? {
    defaultBaseUrl: 'https://api.houseid.services',
    firebaseConfig: {
      apiKey: 'AIzaSyCkCaDxMWYK2veE2NX37uvuSoLQBfmKNu0',
      authDomain: 'houseidapp.firebaseapp.com',
      databaseURL: 'https://houseidapp.firebaseio.com',
      projectId: 'houseidapp',
      storageBucket: 'houseidapp.appspot.com',
      messagingSenderId: '151412820269',
      appId: 'houseidapp',
      domainUriPrefix: 'https://houseid.page.link',
    },
  }
  : {
    defaultBaseUrl: 'https://api.test.houseid.services',
    firebaseConfig: {
      apiKey: 'AIzaSyChDBF0jeWF7Mw6T9Ptz6OLPybtR34X_jU',
      authDomain: 'houseidapp-test.firebaseapp.com',
      databaseURL: 'https://houseidapp-test.firebaseio.com',
      projectId: 'houseidapp-test',
      storageBucket: 'houseidapp-test.appspot.com',
      messagingSenderId: '151412820269',
      domainUriPrefix: 'https://houseid2.page.link',
    },
  };
