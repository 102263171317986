// @ts-nocheck
export interface ProductOffer {
  id?: string,
  imageUrl?: string,
  title?: string,
  price: ProductOfferPrice,
  link: ProductOfferLink,
  type: string,
}

interface ProductOfferPrice {
  price?: number,
  ordinaryPrice?: number,
  discount?: number,
}

interface ProductOfferLink {
  affiliate?: string,
  source?: string,
  url?: string,
}

export interface ProductOfferFormProps {
  productOffers: Array<ProductOffer>,
  fetchExpertsRequest: () => void,
  creteProductOfferRequest: (data: ProductOfferFormatted) => void,
  updateProductOfferRequest: (data: ProductOfferFormatted, expertId: string, id: string) => void,
  pathname: string,
}

export interface ProductOfferPageProps {
  expertId?: string,
  productOffers?: any,
  id?: string,
}

export interface ProductOfferFormatted {
  expertId: string,
  type: string,
  name: string,
  data: ProductOfferFormattedData,
}

export interface ProductOfferFormattedData {
  imageUrl?: string,
  price: ProductOfferPrice,
  link: ProductOfferLink,
}

export const PRODUCT_OFFER_TYPE = 'offer';
export const ANNONS_TYPE = 'anons';
