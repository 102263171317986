import { request, getAdminAPIPath } from '../../../api/core';

export const createProductOffer = (data:any, expertId: string) => request(getAdminAPIPath(`experts/${expertId}/offers`), {
  method: 'POST',
  data,
});

export const fetchProductOffers = (expertId: string) => request(getAdminAPIPath(`experts/${expertId}/offers`), {
  method: 'GET',
});

export const updateProductOffer = (data:any, expertId: string, offerId: string) => request(getAdminAPIPath(`experts/${expertId}/offers/${offerId}`), {
  method: 'PATCH',
  data,
});

export const deleteProductOffer = (expertId: string, offerId: string) => request(getAdminAPIPath(`experts/${expertId}/offers/${offerId}`), {
  method: 'DELETE',
});
