import { request, getAPIPath } from '../../../api/core';

export const propertyCheckRequest = (data: any) => request(getAPIPath('partner/enspecta/property-check'), {
  method: 'POST',
  data,
});

export const fetchPropertyChecks = () => request(getAPIPath('partner/enspecta/admin/property-checks'), {
  method: 'GET',
});
