import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { QueryHook } from '../../../types/common';
import { Expert } from '../../../types/entities';
import { fetchExpertsRequest } from '../reducers/experts';

export const PageSize = 100;
type UseGetExperts = () => QueryHook<Array<Expert>>;

const useGetExperts: UseGetExperts = () => {
  const dispatch = useDispatch();

  const experts = useSelector(({ experts }: RootState) => experts.experts);

  const loading = useSelector(({ experts }: RootState) => experts.expertsLoading);
  const expertsError = useSelector(({ experts }: RootState) => experts.expertsError);

  const fetch = (pageNumber: number = 0, pageSize: number = PageSize) => {
    if (experts.length <= pageNumber * pageSize) {
      dispatch(fetchExpertsRequest(pageNumber, pageSize));
    }
  };

  useEffect(() => {
    if (!loading && _.isEmpty(experts)) {
      fetch();
    }
  }, []);

  return {
    fetch,
    refetch: () => fetch(0),
    data: experts,
    loading,
    error: expertsError,
  };
};

export default useGetExperts;
