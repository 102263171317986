/* eslint-disable @typescript-eslint/default-param-last */
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { uploadBlobsRequest } from '../../../api/blob';
import { showActionErrorAlert, showSuccessAlert } from '../../../store/reducers/alerts';
import { ActionError } from '../../../types/common';
import { sendMessageRequest } from '../api/message';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';

const initState = {
  loading: false,
  error: null,
};

export const messagesReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_MESSAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: return state;
  }
};

export const requestSendMessage = (propertyId: string, message: any, blobs: Array<any>) => ({
  type: SEND_MESSAGE_REQUEST,
  message,
  blobs,
  propertyId,
});

export const sendMessageSuccess = () => ({
  type: SEND_MESSAGE_SUCCESS,
});

export const sendMessageFailed = (error: ActionError) => ({
  type: SEND_MESSAGE_FAILED,
  error,
});

export function* sendMessageSaga({ propertyId, message, blobs }: any): Generator {
  try {
    // @ts-ignore
    const uploadedBlobs: Array<string> = yield call(uploadBlobsRequest, propertyId, blobs);
    const messageWithBlobs = { ...message, blobs: uploadedBlobs.map((id) => ({ id, classifications: [] })) };

    yield call(sendMessageRequest, messageWithBlobs);
    yield put(sendMessageSuccess());
    yield put(showSuccessAlert('Message successfully uploaded to the property'));
  } catch (error) {
    yield put(showActionErrorAlert(error));
    yield put(sendMessageFailed(error));
  }
}

function* watchSendMessageRequest() {
  yield takeLatest(SEND_MESSAGE_REQUEST, sendMessageSaga);
}

export function* messagesSaga() {
  yield all([watchSendMessageRequest()]);
}
