import {
  Container, Typography, Grid, useTheme, Paper, Box,
} from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataType, FieldInfo } from '../types/formBuilder';
import FormDateTimePicker from './FormDateTimePicker';
import FormTextInput from './FormTextInput';
import FormSelector from './FormSelector';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
}));

const dateTypeComponentMapper: Record<DataType, FC<FieldInfo>> = {
  [DataType.STRING]: (props) => <FormTextInput {...props} />,
  [DataType.NUMBER]: (props) => <FormTextInput {...props} />,
  [DataType.LIST]: (props) => <FormSelector {...props} />,
  [DataType.DATE]: (props) => <FormDateTimePicker {...props} />,
};

type FieldsInfo = any;

type FormBuilderProps = {
  fields: Array<FieldsInfo>;
  title: string;
  disabled?: boolean;
  loading?: boolean;
  onSubmit: () => void;
  FooterComponent: ReactElement
};

const FormBuilder: FC<FormBuilderProps> = ({
  fields,
  title,
  disabled,
  loading,
  onSubmit,
  FooterComponent,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const renderTextField = ({
    id, label, required, value, onChange, onBlur, error, touched, type, options,
  }: FieldInfo, index: number) => {
    const Component = dateTypeComponentMapper[type];
    if (!Component) {
      return null;
    }
    return <Component
      autoFocus={index === 0}
      id={id}
      required={required}
      error={error}
      touched={touched}
      label={label}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      type={type}
      options={options}
    />;
  };

  return (
    <Container component="main" maxWidth="xl" className={classes.paper}>
      <Paper variant="elevation" sx={{
        padding: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flex: 1,
        flexDirection: 'column',
      }}>
        <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        <Grid
          sx={{ padding: theme.spacing(1) }}
          container
          spacing={{
            xs: 2, sm: 2, md: 3, lg: 6, xl: 6,
          }}
          columns={{
            xs: 2, sm: 8, md: 12, lg: 12, xl: 12,
          }}
        >
          {fields.map((fieldInfo, index) => (
            <Grid item xs={2} sm={4} md={4} lg={3} key={index}>
              {renderTextField(fieldInfo, index)}
            </Grid>
          ))}
        </Grid>
        <Box>
          <LoadingButton
            variant="contained"
            sx={{ minWidth: theme.spacing(40), marginTop: theme.spacing(2) }}
            onClick={onSubmit}
            disabled={disabled}
            loading={loading}
          >
            Submit
          </LoadingButton>

        </Box>
        {FooterComponent}
      </Paper>
    </Container>
  );
};

export default FormBuilder;
