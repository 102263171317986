import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const renderStatusIcon = (status) => {
  switch (status) {
    case 'created': return (<NewReleasesIcon />);
    case 'confirmed': return (<AccessTimeIcon />);
    case 'locked': return (<LockIcon />);
    case 'completed': return (<CheckCircleOutlineIcon />);
    case 'cancelled': return (<CancelIcon />);
    default: return null;
  }
};

const useGetPropertyCheckColumns = () => [
  {
    field: 'statusIcon',
    headerName: '',
    flex: 0.1,
    valueOptions: ['created', 'confirmed', 'locked', 'completed', 'cancelled'],
    renderCell: (params) => renderStatusIcon(params.row.status),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.6,
    valueOptions: ['created', 'confirmed', 'locked', 'completed', 'cancelled'],
    renderCell: (params) => params.row.status,
  },
  {
    field: 'articleId',
    headerName: 'Article ID',
    flex: 0.8,
  },
  {
    field: 'createdAt',
    headerName: 'Created date',
    type: 'dateTime',
    flex: 0.7,
    renderCell: (params) => (
      <span>
        {new Date(params.row.createdAt).toLocaleString('sv-SE', { timeZone: 'Europe/Stockholm', dateStyle: 'short', timeStyle: 'short' })}
      </span>
    ),
  },
  {
    field: 'requestedDate',
    headerName: 'Requested date',
    type: 'dateTime',
    flex: 0.7,
    renderCell: (params) => (
      <span>
        {new Date(params.row.requestedDate).toLocaleString('sv-SE', { timeZone: 'Europe/Stockholm', dateStyle: 'short', timeStyle: 'short' })}
      </span>
    ),
  },
  {
    field: 'confirmedInspectionDate',
    headerName: 'Confirmed date',
    type: 'dateTime',
    flex: 0.7,
    renderCell: (params) => (
      <span>
        {params.row.confirmedInspectionDate
          ? new Date(params.row.confirmedInspectionDate).toLocaleString('sv-SE', { timeZone: 'Europe/Stockholm', dateStyle: 'short', timeStyle: 'short' })
          : ''}
      </span>
    ),
  },
  {
    field: 'userId',
    headerName: 'User ID',
    flex: 2,
    hide: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'E-mail',
    hide: true,
    flex: 1,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone number',
    hide: true,
    flex: 1,
  },
  {
    field: 'propertyId',
    headerName: 'Property ID',
    flex: 2,
    hide: true,
  },
  {
    field: 'propertyDesignation',
    headerName: 'Property designation',
    flex: 1,
  },
  {
    field: 'address',
    headerName: 'Address',
    sortable: false,
    flex: 1,
    valueFormatter: ({ value: address }) => `${address.streetAddress}, ${address.postalCode} ${address.addressLocality}`,
    renderCell: ({ value: address }) => (<span>{address.streetAddress}<br />{address.postalCode} {address.addressLocality}</span>),
  },
  {
    field: 'enspectaInspector',
    headerName: 'Inspector',
    flex: 1,
    valueFormatter: ({ value: inspector }) => inspector ? `${inspector.name} (${inspector.phone})` : '',
    renderCell: ({ value: inspector }) => inspector ? (<span>{inspector.name} ({inspector.phone})</span>) : <span />,
  },
  {
    field: 'userText',
    headerName: 'User text',
    flex: 1,
    renderCell: ({ value }) => (<textarea>{value}</textarea>),
  },
  {
    field: 'userAcceptedTerms',
    headerName: 'Accepted terms',
    flex: 1,
    hide: true,
    renderCell: ({ value }) => <span>{value === true ? 'Yes' : 'No'}</span>,
  },
  {
    field: 'completedPropertySpecification',
    headerName: 'Property specification',
    flex: 1,
    hide: true,
    renderCell: ({ row }) => row.lockedTaskCompletionData
      ? <span>{row.lockedTaskCompletionData?.propertySpecification === true ? 'Yes' : 'No'}</span>
      : <span />,
  },
  {
    field: 'completedExternalServices',
    headerName: 'External services',
    flex: 1,
    hide: true,
    renderCell: ({ row }) => row.lockedTaskCompletionData
      ? <span>{row.lockedTaskCompletionData?.externalServices === true ? 'Yes' : 'No'}</span>
      : <span />,
  },
  {
    field: 'completedRooms',
    headerName: 'Rooms',
    flex: 1,
    hide: true,
    renderCell: ({ row }) => row.lockedTaskCompletionData
      ? <span>{row.lockedTaskCompletionData?.rooms === true ? 'Yes' : 'No'}</span>
      : <span />,
  },
  {
    field: 'completedCustomTimelineEntries',
    headerName: 'Timeline entries',
    flex: 1,
    hide: true,
    renderCell: ({ row }) => row.lockedTaskCompletionData
      ? <span>{row.lockedTaskCompletionData?.customTimelineEntries === true ? 'Yes' : 'No'}</span>
      : <span />,
  },
  {
    field: 'paymentMethodId',
    headerName: 'Payment method',
    hide: true,
    flex: 0.8,
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    hide: true,
    flex: 0.8,
  },
  {
    field: 'discountPrice',
    headerName: 'Campaign price',
    type: 'number',
    hide: true,
    flex: 0.8,
  },
  {
    field: 'campaignName',
    headerName: 'Campaign name',
    hide: true,
    flex: 0.8,
  },
  {
    field: 'invoiceId',
    headerName: 'Invoice number',
    hide: true,
    flex: 0.8,
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice date',
    type: 'dateTime',
    hide: true,
    flex: 0.7,
    renderCell: ({ value }) => (
      <span>
        {value ? new Date(value).toLocaleString('sv-SE', { timeZone: 'Europe/Stockholm', dateStyle: 'short', timeStyle: 'short' }) : ''}
      </span>
    ),
  },
  {
    field: 'invoiceAmount',
    headerName: 'Invoice amount',
    type: 'number',
    hide: true,
    flex: 0.8,
  },
];

export default useGetPropertyCheckColumns;
