// @ts-nocheck
import _ from 'lodash';
import { ActionError } from '../types/common';

const DefaultErrorCode = 'UNHANDLED_BACKEND_ERROR';
const DefaultErrorMessage = 'ERROR';
export const DefaultAppErrorCode = 'APP_ERROR';

export const getActionError = (errorResponse: any): ActionError => {
  const response = errorResponse?.response || errorResponse;
  const currentError = _.first(response?.data?.errors) || response;
  const status = errorResponse?.response?.status || '';

  return {
    code: currentError?.code || DefaultErrorCode,
    message: currentError?.message || currentError?.msg || currentError?.name || DefaultErrorMessage,
    statusCode: Number(status),
    url: errorResponse?.response?.url,
    headers: errorResponse?.response?.headers?.map,
  };
};
