import axios from 'axios';
import { getActionError } from '../services/actionError';
import firebase from '../services/firebase';
import { AppSettings } from '../settings/appSettings';
import { ResponseData } from '../types/common';

const { defaultBaseUrl } = AppSettings;
const envAdminAPIPath = `${defaultBaseUrl}/admin`;
const envAPIPath = defaultBaseUrl;

export const getAdminAPIPath = (path: string) => `${envAdminAPIPath}/${path}`;
export const getAPIPath = (path: string) => `${envAPIPath}/${path}`;

export const checkOk = (response) => {
  const { data } = response;

  // If response contains an error message, reject
  if (response.status < 200 && response.status > 299) {
    return Promise.reject(getActionError(response));
  }
  return {
    data,
  };
};

export async function request<TData>(
  path: string,
  params: any,
): Promise<ResponseData<TData>> {
  const token = await firebase.getAccessToken() || window.localStorage.getItem('houseIdToken');
  if (!token) {
    return Promise.reject(
      'Missing access_token, needed to perform this request',
    );
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  return axios({ ...params, ...config, url: path })
    .catch((err) => Promise.reject(getActionError(err)))
    .then(checkOk);
}

export async function postFormData(
  path: string,
  formData: FormData,
): Promise<ResponseData<any>> {
  const token = await firebase.getAccessToken() || window.localStorage.getItem('houseIdToken');
  if (!token) {
    return Promise.reject(
      'Missing access_token, needed to perform this request',
    );
  }
  const config = { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` } };
  return axios.post<any, void>(path, formData, config)
    .catch((err) => Promise.reject(getActionError(err)))
    .then(checkOk);
}

export async function unauthorizedRequest<TData>(path: string, params: any): Promise<ResponseData<TData>> {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  return axios({ ...params, ...config, url: path })
    .catch((err) => Promise.reject(getActionError(err)))
    .then(checkOk);
}
