// @ts-nocheck
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { ProductOfferFormProps } from '../../../../../types/productOfferProperties';
import SearchDialog from '../../../../../components/SearchDialog';
import { SubmitButton } from '../../../../../components';

const columns = [
  {
    field: 'id', headerName: 'ID', width: 150, type: 'string',
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 350,
    editable: false,
    type: 'string',
  },
  {
    field: 'ingress',
    headerName: 'Description',
    width: 680,
    editable: false,
    type: 'string',
  },
];

export default function InnerForm(
  props: ProductOfferFormProps & FormikProps<any>,
) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 10,
    },
    formControlArticles: {
      width: '100%',
    },
  }));

  const classes = useStyles();
  const {
    touched,
    errors,
    isSubmitting,
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    setFieldValue,
    pathname,
  } = props;
  const {
    title,
    imageUrl,
    price,
    discount,
    ordinaryPrice,
    affiliate,
    source,
    url,
    experts,
    expertId,
    validTo,
    validFrom,
    navigationData,
  } = values;

  const params = useParams();

  const getName = () => (pathname.indexOf('createAnnons') >= 0 || pathname.indexOf('editAnnons') >= 0
    ? Object.hasOwnProperty.call(params, 'id')
      ? 'Edit annons'
      : 'Create annons'
    : Object.hasOwnProperty.call(params, 'id')
      ? 'Edit product offer'
      : 'Create product offer');

  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="xl">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {getName()}
        </Typography>

        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            if (Object.keys(errors).length === 0) {
              handleSubmit();
              navigate(-1);
            }
          }}
        >
          <Container className={classes.row} maxWidth="xl">
            <Container maxWidth="xs">
              <TextField
                error={
                  touched.title ? Object.hasOwnProperty.call(errors, 'title') : null
                }
                fullWidth
                helperText={touched.title && errors.title ? errors.title : null}
                label="Title"
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={title}
                variant="standard"
              />

              <TextField
                error={
                  touched.imageUrl ? Object.hasOwnProperty.call(errors, 'imageUrl') : null
                }
                fullWidth
                helperText={
                  touched.imageUrl && errors.imageUrl ? errors.imageUrl : null
                }
                label="Image Url"
                margin="normal"
                name="imageUrl"
                onBlur={handleBlur}
                onChange={handleChange}
                value={imageUrl}
                variant="standard"
              />

              <TextField
                error={
                  touched.price ? Object.hasOwnProperty.call(errors, 'price') : null
                }
                fullWidth
                helperText={touched.price && errors.price ? errors.price : null}
                label="Price"
                margin="normal"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={price}
                variant="standard"
              />

              <TextField
                error={
                  touched.ordinaryPrice ? Object.hasOwnProperty.call(errors, 'ordinaryPrice') : null
                }
                fullWidth
                helperText={
                  touched.ordinaryPrice
                    && errors.ordinaryPrice ? errors.ordinaryPrice : null
                }
                label="Ordinary Price"
                margin="normal"
                name="ordinaryPrice"
                onBlur={handleBlur}
                onChange={handleChange}
                value={ordinaryPrice}
                variant="standard"
              />

              {!expertId && (
                <SearchDialog
                  buttonTitle="Add/Remove expert"
                  columns={columns}
                  data={experts.map((expert: any) => ({
                    id: expert.id,
                    title: expert.place.name,
                    address: `${expert.place.address.addressLocality}, ${expert.place.address.streetAddress}`,
                  }))}
                  pickedItemsSetter={(expertId: string) => setFieldValue('expertId', expertId[0])}
                  searchTitle="Experts"
                  selectionModel={expertId}
                  supportMultiSelection={false}
                />
              )}

              <TextField
                disabled
                error={
                  touched.expertId ? Object.hasOwnProperty.call(errors, 'expertId') : null
                }
                fullWidth
                helperText={
                  (touched.expertId && errors.expertId && errors.expertId)
                  || [experts.find(({ id }: any) => id === expertId)]?.map(
                    (expert: any) => `${expert.place.address.addressLocality}, ${expert.place.address.streetAddress}`,
                  )
                }
                label="Expert"
                margin="normal"
                name="expertId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={[experts.find(({ id }: any) => id === expertId)].map(
                  (expert: any) => expert.place?.name,
                )}
                variant="standard"
              />

              <TextField
                error={
                  touched.navigationData ? Object.hasOwnProperty.call(errors, 'navigationData') : null
                }
                fullWidth
                helperText={
                  touched.navigationData
                    && errors.navigationData ? errors.navigationData : null
                }
                label="Navigation data"
                margin="normal"
                multiline
                name="navigationData"
                onBlur={handleBlur}
                onChange={handleChange}
                rows={4}
                value={navigationData}
                variant="standard"
              />
            </Container>

            <Container maxWidth="xs">
              <TextField
                error={
                  touched.discount ? Object.hasOwnProperty.call(errors, 'discount') : null
                }
                fullWidth
                helperText={
                  touched.discount && errors.discount ? errors.discount : null
                }
                label="Discount"
                margin="normal"
                name="discount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={discount}
                variant="standard"
              />

              <TextField
                error={
                  touched.affiliate ? Object.hasOwnProperty.call(errors, 'affiliate') : null
                }
                fullWidth
                helperText={
                  touched.affiliate && errors.affiliate ? errors.affiliate : null
                }
                label="Affiliate"
                margin="normal"
                name="affiliate"
                onBlur={handleBlur}
                onChange={handleChange}
                value={affiliate}
                variant="standard"
              />

              <TextField
                error={
                  touched.source ? Object.hasOwnProperty.call(errors, 'source') : null
                }
                fullWidth
                helperText={touched.source && errors.source ? errors.source : null}
                label="Source"
                margin="normal"
                name="source"
                onBlur={handleBlur}
                onChange={handleChange}
                value={source}
                variant="standard"
              />

              <TextField
                error={touched.url ? Object.hasOwnProperty.call(errors, 'url') : null}
                fullWidth
                helperText={touched.url && errors.url ? errors.url : null}
                label="Url"
                margin="normal"
                name="url"
                onBlur={handleBlur}
                onChange={handleChange}
                value={url}
                variant="standard"
              />

              <TextField
                error={
                  touched.validFrom ? Object.hasOwnProperty.call(errors, 'validFrom') : null
                }
                fullWidth
                helperText={
                  touched.validFrom && errors.validFrom ? errors.validFrom : null
                }
                label="Valid from"
                margin="normal"
                name="validFrom"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="YYYY-MM-DD"
                value={validFrom}
                variant="standard"
              />

              <TextField
                error={
                  touched.validTo ? Object.hasOwnProperty.call(errors, 'validTo') : null
                }
                fullWidth
                helperText={touched.validTo && errors.validTo ? errors.validTo : null}
                label="Valid To"
                margin="normal"
                name="validTo"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="YYYY-MM-DD"
                value={validTo}
                variant="standard"
              />
            </Container>
          </Container>

          <SubmitButton
            disabled={isSubmitting || Object.keys(errors).length > 0}
            title={Object.hasOwnProperty.call(params, 'id') ? 'Save' : 'Create'}
          />
        </form>
      </div>
    </Container>
  );
}
