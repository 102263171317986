import * as React from 'react';

import { Button } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PendingIcon from '@mui/icons-material/Pending';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { useNavigate } from 'react-router-dom';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { useTheme } from '@material-ui/core';
import DataGridToolbar from '../../../../../components/DataGridToolbar';

const PropertyCheckDataGridToolbar = ({ onDataGridModifiersChanged }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleAddNewPropertyCheck = () => {
    navigate('/addPropertyCheckRequest');
  };

  const allClick = () => {
    onDataGridModifiersChanged({
      rowFilter: () => true,
      columnFunc: (column) => column,
    });
  };

  const pendingClick = () => {
    onDataGridModifiersChanged({
      rowFilter: (pc: any) => ['created', 'confirmed', 'locked'].includes(pc.status),
      columnFunc: (column) => ({
        ...column,
        hide: !['statusIcon', 'status', 'articleId', 'createdAt', 'requestedDate', 'confirmedInspectionDate',
          'name', 'email', 'propertyDesignation', 'address',
        ].includes(column.field),
      }),
    });
  };

  const readyToBeInvoicedClick = () => {
    onDataGridModifiersChanged({
      rowFilter: (pc: any) => (pc.status === 'completed' && pc.invoiceId === null),
      columnFunc: (column) => ({
        ...column,
        hide: !['articleId', 'name', 'email', 'propertyDesignation', 'address', 'userText',
          'userAcceptedTerms', 'completedPropertySpecification', 'completedExternalServices',
          'completedRooms', 'completedCustomTimelineEntries', 'price', 'campaignPrice', 'campaignName',
        ].includes(column.field),
      }),
    });
  };

  return (
    <DataGridToolbar>
      <div>
        <Button color="primary" variant="text" onClick={handleAddNewPropertyCheck}>
          <AddTaskIcon style={{ marginRight: theme.spacing(1) }} />
          Add property check
        </Button>
        <Button color="primary" variant="text" onClick={allClick}>
          <FormatAlignJustifyIcon />
          All
        </Button>
        <Button color="primary" variant="text" onClick={pendingClick}>
          <PendingIcon />
          Pending
        </Button>
        <Button color="primary" variant="text" onClick={readyToBeInvoicedClick}>
          <ReceiptLongIcon />
          Ready to be invoiced
        </Button>
      </div>
    </DataGridToolbar>
  );
};
export default PropertyCheckDataGridToolbar;
